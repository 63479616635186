<template>
    <div class="course-storage__wrapper" v-if="storage">
        <template v-for="item in storage">
            <div class="course-storage" v-bind:key="item.id" v-if="item.title">
                <div class="course-storage__head">{{ item.title }}</div>

                <div class="course-storage__items" @drop="onDrop($event, item)" @dragover.prevent @dragenter.prevent>
                    <div class="course-storage__items-wrapper" v-if="item.courseitemcontent" data-draganddrop>
                        <template v-for="(courseitemcontent, index) in item.courseitemcontent">
                            <CourseDayDayItem v-bind:key="courseitemcontent.id" :courseDayDayItem.sync="item.courseitemcontent[index]"
                                              :isCourseOwner="isCourseOwner"/>
                        </template>
                    </div>

                    <md-button class="md-primary md-just-icon md-round md-sm" v-if="isCourseOwner"
                               @click="addStorageContent(item)">
                        <md-icon>add</md-icon>
                    </md-button>
                </div>
            </div>
        </template>
    </div>
</template>


<script>

import CourseDayDayItem from "@/components/custom/CourseDayDayItem.vue";

export default {
    components: {CourseDayDayItem},
    props: {
        course: {type: Object, default: null},
        storage: {type: Array, default: null},
        isCourseOwner: {type: Boolean, default: false}
    },
    data() {
        return {
            updateStorageTimer: null,
        }
    },
    mounted() {
        this.getStorage();
    },
    methods: {
        getStorage() {
            this.$parent.getCourseItems();
        },
        addStorageContent(item) {
            this.directus.items('courseitemcontent').createOne({
                courseitem: item.id,
            }).then(() => {
                this.getStorage();
            })
        },
        getCourseItemContent() {
            this.getStorage();
        },
        onDrop(e, courseDay) {
            this.$parent.onDrop(e, courseDay);
        },
    }
}
</script>