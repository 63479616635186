import Vue from "vue";
import Router from "vue-router";

//  Main Pages
import Index from "./views/Index.vue";
import Sections from "./views/Sections.vue";
import Presentation from "./views/Presentation.vue";

//  Main Examples
import Landing from "./views/examples/Landing.vue";
//import Login from "./views/examples/Login.vue";
import Profile from "./views/examples/Profile.vue";
import AboutUs from "./views/examples/AboutUs.vue";
import BlogPost from "./views/examples/BlogPost.vue";
import BlogPosts from "./views/examples/BlogPosts.vue";
import ContactUs from "./views/examples/ContactUs.vue";
import Pricing from "./views/examples/Pricing.vue";
import Shopping from "./views/examples/Shopping.vue";
import Ecommerce from "./views/examples/Ecommerce.vue";
import Product from "./views/examples/Product.vue";
import Signup from "./views/examples/Signup.vue";
import Error from "./views/examples/Error.vue";

import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";

// Pages
import Home from "./views/pages/Home.vue";
import Login from "./views/pages/Login.vue";
import Register from "./views/pages/Register.vue";
import Forgot from "./views/pages/Forgot.vue";
import Users from "./views/pages/Users";
import Groups from "./views/pages/Groups";
import Groupdetail from "./views/pages/Groupdetail";
import Userdetail from "./views/pages/Userdetail";
import Editprofile from "./views/pages/Editprofile";
import Courses from "./views/pages/Courses";
import Coursedetail from "./views/pages/Coursedetail";
import ResetPassword from "./views/pages/ResetPassword";

// user service
import {userService} from "@/services/user.service";
import FinishRegister from "./views/pages/FinishRegister";

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: "/",
            name: "home",
            components: {
                default: Home,
                header: MainNavbar,
                footer: MainFooter
            },
            props: {
                header: {colorOnScroll: 400, navbarTransparent: true}
            }
        },
        {
            path: "/login",
            name: "login",
            components: {default: Login, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400, navbarTransparent: true}
            }
        },
        {
            path: "/register",
            name: "registrieren",
            components: {default: Register, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400, navbarTransparent: true}
            },
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/finishregister",
            name: "finishregister",
            components: {default: FinishRegister, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400, navbarTransparent: true}
            },
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/forgot",
            name: "forgot",
            components: {default: Forgot, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400, navbarTransparent: true}
            },
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/resetpassword",
            name: "resetpassword",
            components: {default: ResetPassword, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400, navbarTransparent: true}
            },
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/profile",
            name: "profile",
            components: {default: Profile, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400, navbarTransparent: true}
            },
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/users",
            name: "users",
            components: {default: Users, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400, navbarTransparent: true}
            },
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/groups",
            name: "groups",
            components: {default: Groups, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400, navbarTransparent: true}
            },
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/groupdetail/:groupid",
            name: "groupdetail",
            components: {default: Groupdetail, header: MainNavbar, footer: MainFooter},
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/userdetail/:userid",
            name: "userdetail",
            components: {default: Userdetail, header: MainNavbar, footer: MainFooter},
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/editprofile",
            name: "editprofile",
            components: {default: Editprofile, header: MainNavbar, footer: MainFooter},
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/courses",
            name: "courses",
            components: {default: Courses, header: MainNavbar, footer: MainFooter},
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/coursedetail/:courseid",
            name: "coursedetail",
            components: {default: Coursedetail, header: MainNavbar, footer: MainFooter},
            meta: {
                requiresAuth: true
            }
        },
    ],

    scrollBehavior: to => {
        if (to.hash) {
            return {selector: to.hash};
        } else {
            return {x: 0, y: 0};
        }
    },
});


router.beforeEach((to, from, next) => {
    if (to.name === 'login') {
        next() // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
    } else if (to.meta && to.meta.requiresAuth === false) {
        next() // requires auth is explicitly set to false
    } else if (userService.getUser().isLoggedIn) {
        next() // i'm logged in. carry on
    } else {
        userService.refreshAuth()
            .catch(() => {
                next({name: 'login'});
            })
            .then(response => {
                if (!response || !response.access_token) {
                    next({name: 'login'}) // always put your redirect as the default case
                } else {
                    next();
                }
            });
    }
})

export default router;