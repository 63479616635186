<template>
  <div class="wrapper">
    <notifications ref="notifications"/>
    <div class="section page-header header-filter" :style="headerStyle" >
      <div class="container">
        <div class="md-layout">
          <div class="login__wrapper">
            <form @submit="mailLogin" id="signup">
              <login-card header-color="green">


                <md-button slot="buttons" class="md-simple md-white" disabled>
                  Login
                </md-button>

                <!--
                <md-button slot="buttons" href="javascript:void(0)" class="md-just-icon md-simple md-white" >
                  <i class="fab fa-facebook-square"/>
                </md-button>

                <md-button slot="buttons" href="javascript:void(0)" class="md-just-icon md-simple md-white">
                  <i class="fab fa-google"/>
                </md-button>

                -->

                <md-field slot="inputs" class="md-form-group" id="email">
                  <md-icon>email</md-icon>
                  <label>E-Mail</label>
                  <md-input v-model="email" type="email" name="email" required/>
                  <span class="md-error">Bitte eine korrekte E-Mail Addresse eingeben</span>
                </md-field>

                <md-field slot="inputs" class="md-form-group" id="password">
                  <md-icon>lock_outline</md-icon>
                  <label>Passwort</label>
                  <md-input v-model="password" type="password" name="password" required/>
                  <span class="md-error">Bitte Ihr Passwort eingeben</span>
                </md-field>

                <md-button type="submit" slot="footer" class="md-simple md-success md-lg">Login</md-button>

                <a href="/#/forgot" slot="footer"  class="md-button-clean">
                    <md-button slot="title" class="md-simple md-info md-white md-lg">Passwort vergessen</md-button>
                </a>
              </login-card>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {LoginCard} from "@/components";
import {Notifications} from "@/components";
import Mixins from "@/plugins/basicMixins";
import {userService} from "@/services/user.service";

export default {
  components: {
    LoginCard,
    Notifications
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "login-page",
  data() {
    return {
      image: require("@/assets/img/profile_city.jpg"),
      firstname: null,
      fields: {},
      email: null,
      password: null,
      errorClass: 'md-invalid',
      hasError: false,
      notifications: [],
      mailRegex: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
    };
  },
  mounted: function () {
    this.fields['email'] = document.getElementById('email')
    this.fields['password'] = document.getElementById('password')
    userService.logout();
  },
  methods: {
    mailLogin: function (e) {
      e.preventDefault();
      this.hasError = false;

      for (let key in this.fields) {
        let field = this.fields[key]
        let input = field.querySelector('input')

        if(input.value === '') {
          this.handleFieldError(field, 'add');
          this.hasError = true;
        } else {
          this.handleFieldError(field);
        }

        if(key === 'email') {
          if(!this.mailRegex.test(input.value)) {
            this.handleFieldError(field, 'add');
            this.hasError = true;
          }
        }
      }

      if(this.hasError === false) {
        userService.login(this.email, this.password).then(response => {
          if(response.success === true) {
            this.$router.push('/')
          } else {
            this.$parent.alertManager('<b>Anmeldung Fehlgeschlagen:</b> Ihre Benutzerdaten sind nicht korrekt, bitte versuchen Sie es erneut.', 'error-message', false, 5000, {type: 'loginError', message: response})
          }
        })
      }
    },
    handleFieldError: function (field, action = 'remove') {
      if(action === 'remove') {
        field.classList.remove(this.errorClass)
      } else if(action === 'add') {
        field.classList.add(this.errorClass)
      }
    }
  }
};
</script>

<style lang="css"></style>
