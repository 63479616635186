<template>
  <div class="card__wrapper" @click.stop="showDetailPage(group.id)">
    <md-card class="card--group" :class="{'card--disabled' : disabled}">
      <div class="card__icon">
        <md-icon>group</md-icon>
      </div>
      <md-card-content>
        <div class="card__body">
          <h1 class="card-title">{{ group.title }}</h1>
          <p class="card-description" v-if="group.description">{{ group.description }}</p>
        </div>
      </md-card-content>

      <md-card-actions v-if="$slots.cardAction" class="justify-content-center">
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
export default {
  name: "GroupCard",
  props: {
    group: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showDetailPage(id) {
      this.$router.push({ name: 'groupdetail', params: { groupid: id } })
    },
  }
};
</script>

<style lang="scss">
.justify-content-center {
  justify-content: center !important;
}
</style>
