import {directusService} from "@/services/directus.service";

const directus = directusService.init();

export const userService = {
    login,
    logout,
    register,
    getUser,
    updateUser,
    refreshAuth,
    resetPassword,
    updateResetPassword,
    inviteUser,
    acceptInvite
};

function getUser() {
    let token = localStorage.getItem('auth_token');
    let date = localStorage.getItem('auth_expires');
    let auth_expires_at = localStorage.getItem('auth_expires_at');
    if (auth_expires_at > 0) {
        date = auth_expires_at;
    }
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && token && date >= new Date().getTime()) {
        return {
            success: true,
            isLoggedIn: true,
            isAdmin: currentUser.isAdmin,
            user: currentUser,
        }
    } else {
        return {
            success: true,
            isLoggedIn: false,
            isAdmin: false,
        }
        userService.logout();
    }
}

function updateUser(userUpdate) {
    return directus.users.me.update({
        title: userUpdate.title,
        first_name: userUpdate.first_name,
        last_name: userUpdate.last_name,
        description: userUpdate.description,
        location: userUpdate.location,
        tags: userUpdate.tags,
        avatar: userUpdate.avatar
    }).then(response => {
        let updatesUser = createLocalUser(response);
        updateGlobalUserVar(updatesUser);
        return updatesUser;
    });
}

function login(username, password) {
    userService.logout();
    return directus.auth.login({
        email: username,
        password: password,
    }).then(response => {
        return directus.users.me.read().then(currentUser => {
            currentUser = createLocalUser(currentUser);
            updateGlobalUserVar(currentUser, currentUser.isAdmin, true, true);
            return {
                success: true,
                response: response,
                user: currentUser
            }
        });
    }).catch(response => {
        return {
            success: false,
            response: response
        }
    })
}

function createLocalUser(currentUser) {
    delete currentUser.last_access
    delete currentUser.last_page
    delete currentUser.password
    delete currentUser.tfa_secret
    delete currentUser.token
    currentUser.isAdmin = currentUser.role === '3844ac0f-4329-42db-8b26-0c9173558018';
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    return currentUser;
}

function logout() {
    updateGlobalUserVar('', false, false, true);
    return new Promise((resolve) => {
        localStorage.removeItem('auth_token')
        localStorage.removeItem('auth_expires')
        localStorage.removeItem('currentUser')
        resolve(true)
    })
}

function register(currentUser) {
    return directus.users.createOne({
        first_name: currentUser.firstname,
        last_name: currentUser.name,
        email: currentUser.email,
        password: currentUser.password,
        language: 'de-DE',
        status: 'active',
        role: '0b6f3f90-4885-4fde-8e74-5c997c271c24'
    }).then(response => {
        return {
            success: true,
            user: response
        }
    }).catch(response => {
        return {
            success: false,
            error: response
        }
    })
}

function updateGlobalUserVar(user = null, isAdmin = null, isLoggedIn = null, success = null) {
    if (user) {
        $user.user = user;
    }

    if (isAdmin) {
        $user.isAdmin = isAdmin;
    }

    if (isLoggedIn) {
        $user.isLoggedIn = isLoggedIn;

        if (isLoggedIn === false) {
            $user.user = null;
            $user.isLoggedIn = false;
        }
    }

    if (success) {
        $user.success = success;
    }
}

function refreshAuth() {
    return new Promise((resolve, reject) => {
        let auth_expires_at = localStorage.getItem('auth_expires_at');
        let auth_token = localStorage.getItem('auth_token');

        if (Date.now() < auth_expires_at) {
            resolve(auth_token);
            return auth_token;
        }


        return directus.auth.refresh()
            .then(() => {
                resolve(localStorage.getItem('auth_token'));
            })
            .catch(error => {
                console.log("LOGIN ERROR: ");
                console.log(error);
                reject(error);
            });
    });
}

function resetPassword(email = null) {
    if (!email) {
        email = $user.user.email;
    }
    return directus.auth.password.request(email, window.location.protocol + '//' + window.location.host + '/#/resetpassword');
}

function updateResetPassword(token, password) {
    return directus.auth.password.reset(token, password);
}

function inviteUser(email) {
    return directus.users.invites.send(email, '0b6f3f90-4885-4fde-8e74-5c997c271c24', window.location.protocol + '//' + window.location.host + '/#/finishregister')
}

function acceptInvite(token, password) {
    return directus.users.invites.accept(token, password);
}

