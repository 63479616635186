<template>
  <div class="card__wrapper" @click.stop="showDetailPage(course.id)">
    <md-card class="card--course md-card--full-height draganddrop__content" :class="{'card--disabled' : disabled}">
      <div class="draganddrop__trigger draganddrop__trigger--top-right" :draggable="true" @drag="handleDrag($event, course)"
           @dragstart="startDrag($event, course)"><md-icon>drag_indicator</md-icon></div>
      <md-card-content>
        <div class="card__body">
          <h4 class="card-title">{{ course.title }}</h4>
          <p class="card-description">{{ course.description | truncate(200, '...') }}</p>
          <a href="#" @click.stop="showUserDetailPage($event, course.owner.id)" class="card__owner">
            <md-icon>account_circle</md-icon>
            {{ course.owner.first_name }} {{ course.owner.last_name }}</a>
        </div>
      </md-card-content>
      <md-card-actions v-if="$slots.cardAction" class="justify-content-center">
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
import {factory} from "../../services/factory";

export default {
  name: "CourseCard",
  props: {
    course: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showDetailPage(id) {
      this.$router.push({name: 'coursedetail', params: {courseid: id}})
    },
    showUserDetailPage(e, userid) {
      e.preventDefault();
      this.$router.push({name: 'userdetail', params: {userid: userid}})
    },
    startDrag(e, item) {
      factory.startDrag(e, item.id);
    },
    handleDrag(e) {
      factory.handleDrag(e);
    },
  }
};
</script>

<style lang="scss">
.justify-content-center {
  justify-content: center !important;
}
</style>
