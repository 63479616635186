<template>
  <div class="wrapper">
    <parallax
        class="section page-header header-filter"
        parallax-active="true"
        :style="headerStyle"
    />
    <div class="main main-raised">
      <div class="section users-content">
        <div class="container">
          <div class="userdetail userdetail--edit" v-if="user">
            <h2>Steckbrief</h2>
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <div class="md-layout">

                  <div class="md-layout-item md-size-50">
                    <div class="userdetail__avatar">
                      <img class="userdetail__avatar-image" :src="userImage" alt="Person Poster" v-if="userImage">
                    </div>
                  </div>

                  <div class="md-layout-item md-size-50">
                    <Dropzone v-model="user.avatar"/>
                  </div>

                  <div class="md-layout-item md-size-100">
                    <md-field>
                      <label>Titel</label>
                      <md-input v-model="user.title"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>Vorname</label>
                      <md-input v-model="user.first_name"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>Name</label>
                      <md-input v-model="user.last_name"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-100">
                    <md-field>
                      <label>Über mich</label>
                      <md-textarea v-model="user.description"></md-textarea>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-100">
                    <md-field>
                      <label>Ort</label>
                      <md-input v-model="user.location"></md-input>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-size-100 userdetail__addtag userdetail__addtag--empty">
                    <md-field>
                      <label>Interessen</label>
                      <md-input v-model="newTag" v-on:keyup="handleUserTag($event)"></md-input>
                      <div class="userdetail__addtag-icon" v-on:click="handleUserTag($event)">
                        <md-icon>add</md-icon>
                      </div>

                    </md-field>
                    <ul class="userdetail__tags">
                      <li class="userdetail__tag text--small"
                          v-for="(tag, index) in user.tags"
                          v-bind:key="index"
                          :data-index="index"
                          v-on:click="handleUserTag($event)">
                        {{tag}}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="display--flex-between">
                  <md-button class="md-button md-success" @click="updateUser()">Speichern</md-button>
                  <md-button class="md-button md-info" @click="resetPassword()">Passwort ändern</md-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Mixins from "@/plugins/basicMixins";
import { userService } from "@/services/user.service";
import Dropzone from "@/components/custom/Dropzone.vue"
import {factory} from "../../services/factory";

export default {
  components: {Dropzone},
  mixins: [Mixins.HeaderImage],
  bodyClass: "page--content",
  props: {
    image: {
      type: String,
      default: require("@/assets/img/bg2.jpg")
    },
  },
  data() {
    return {
      user: null,
      cloneUser: null,
      userChanged: false,
      newTag: null,
      addTagEmptyClass: 'userdetail__addtag--empty',
      userImage: null
    };
  },
  computed: {
  },
  mounted() {
    this.init()
  },
  methods: {
    init: function () {
      this.getUser();
    },
    getUser() {
      this.directus.users.me.read({
        fields: [
          '*',
          'avatar.*',
        ],
      }).then(response => {
        this.user = response
        this.setImage();
      })
    },
    handleUserTag(e = null) {
      if(e.target.closest('.userdetail__tag') && e.type === 'click') {
        // DELETE TAG
        this.user.tags.splice(e.target.dataset.index, 1);
      } else if(e.type === 'keyup') {
        if(e.keyCode === 13) {
          // ADD TAG
          this.addUserTag(e);
        } else {
          // SHOW / HIDE ADD ICON
          if(this.newTag.length >= 1) {
            e.target.closest('.userdetail__addtag').classList.remove(this.addTagEmptyClass)
          } else {
            e.target.closest('.userdetail__addtag').classList.add(this.addTagEmptyClass)
          }
        }
      } else if(e.target.closest('.userdetail__addtag') && e.type === 'click') {
        this.addUserTag(e)
      }
    },
    addUserTag(e) {
      if(this.user.tags === null) {
        this.user.tags = [];
      }
      if(this.newTag && this.newTag.length > 1) {
        this.user.tags.push(this.newTag);
        this.newTag = "";
        e.target.closest('.userdetail__addtag').classList.add(this.addTagEmptyClass)
      }
    },
    updateUser() {
      userService.updateUser(this.user).then(() => {
        this.$parent.alertManager('Dein Profil', 'success')
        this.getUser();
      })
    },
    async setImage() {
      if(this.user.avatar) {
        factory.createFileUrl(this.user.avatar, '&fit=cover&width=300&quality=80').then(fileUrl => {
          this.userImage = fileUrl;
        })
      } else {
        this.userImage = require("@/assets/img/placeholder.jpg");
      }
    },
    resetPassword() {
      userService.resetPassword().then(() => {
        this.$parent.alertManager('Dir wurde eine Mail mit dem Link für das Ändern des Passworts zugeschickt')
      });
    },
    afterFileUpload(file) {
      this.user.avatar = file.id;
      this.updateUser();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
