<!-- eslint-disable vue/no-mutating-props -->

<template>
  <div class="course__items-wrapper" v-if="hidecourseitems !== true">
    <h4>Kursbausteine</h4>
    <div class="course__items" v-if="courseItems" @drop="onDrop($event)" @dragover.prevent @dragenter.prevent
         data-draganddrop>
      <div class="course__item" v-for="(courseItem, index) in courseItems" v-bind:key="courseItem.id"
           :id="courseItem.id" :data-sort="courseItem.sort" data-draganddrop-item>
        <CourseItem :courseItem.sync="courseItems[index]" :isCourseOwner="isCourseOwner" v-if="courseItem"/>
      </div>

      <div class="course__item" v-if="isCourseOwner">
        <div class="courseitem courseitem--new">
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <md-field>
                <label>Titel</label>
                <md-input v-model="newCourseItem.title"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-60">
              <md-field>
                <label>Sortierung</label>
                <md-input v-model="newCourseItem.sort"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-40">
              <md-button class="md-primary md-just-icon md-round md-sm" @click="addCourseItem()">
                <md-icon>add</md-icon>
              </md-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable vue/no-mutating-props */

import {factory} from "@/services/factory";
import CourseItem from "@/components/custom/CourseItem.vue";

export default {
  name: "CourseItems",
  components: {CourseItem},
  props: {
    courseId: String,
    hidecourseitems: Boolean,
    courseItems: {
      type: Array,
      default: null
    },
    isCourseOwner: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newCourseItem: {},
      dragActiveClass: 'course__items--onDrag'
    }
  },
  methods: {
    getCourseItems() {
      this.$parent.getCourseItems();
    },
    addCourseItem() {
      this.directus.items('courseitem').createOne({
        course: this.courseId,
        title: this.newCourseItem.title,
        sort: this.newCourseItem.sort,
        type: 'courseItem'
      }).then(() => {
        this.newCourseItem = {};
        this.getCourseItems();
      })
    },
    onDrop(e) {
      if (!this.isCourseOwner || !e.dataTransfer.getData('parentId')) {
        //@todo error
        return;
      }

      let promises = factory.onDrop(e, 'courseitem');
      Promise.all(promises).then(() => {
        this.$parent.getCourseItems();
      });
    },
  }
};
</script>