<template>
    <div class="wrapper">
        <div class="main">
            <div class="section users-content">
                <div class="course" v-if="course" :class="[isCourseOwner ? 'course--owned' : 'course--no-pointer']">
                    <div class="course__header container">
                        <div class="display--flex-between">
                            <h2 class="course__title">{{ course.title }}</h2>
                            <template v-if="isCourseOwner">
                                <md-button class="md-primary md-just-icon md-round" @click.stop="openCourseModal()">
                                    <md-icon>edit</md-icon>
                                </md-button>
                            </template>
                        </div>
                        <p class="text--large course__description" v-html="course.description"></p>
                    </div>

                    <div class="course__days-wrapper" v-if="!isSubcourse">
                        <div class="container-fluid">
                            <div class="course__days-navigation" v-if="course.hidecoursedays !== true">
                                <div class="course__pagination" ref="coursePagination">
                                    <md-button class="md-primary md-round md-sm course__pagination-button" v-for="(courseitem, index) in
                course.courseitem" v-bind:key="courseitem.id" @click="courseNavigation('index', index)"
                                               :class="{'course__pagination-button--active' : index === courseDayView.currentIndex}">
                                        {{ courseitem.date | formatToDate('navigation') }}
                                    </md-button>
                                </div>
                                <div class="course__navigation">
                                    <md-button class="md-primary md-just-icon md-round md-sm"
                                               @click="courseNavigation('prev')"
                                               :disabled="courseDayView.currentIndex === 0">
                                        <md-icon>keyboard_arrow_left</md-icon>
                                    </md-button>
                                    <md-button class="md-primary md-just-icon md-round md-sm"
                                               @click="courseNavigation('next')"
                                               :disabled="courseDayView.currentIndex === course.courseitem.length - 1">
                                        <md-icon>keyboard_arrow_right</md-icon>
                                    </md-button>
                                </div>
                            </div>
                            <div class="course__days">
                                <div class="course__day course__day--prev" v-if="course.hidecoursedays !== true">
                                    <CourseDay :courseDay.sync="courseDayView.prev" v-if="courseDayView.prev"
                                               :isCourseOwner="isCourseOwner"/>
                                </div>

                                <div class="course__day course__day--current"
                                     :class="{'course__day--fullwidth' : course.hidecoursedays}">
                                    <template v-if="course.hidecoursedays !== true">
                                        <CourseDay :courseDay.sync="courseDayView.current" v-if="courseDayView.current"
                                                   :isCourseOwner="isCourseOwner"/>
                                    </template>
                                    <CourseItems :isCourseOwner="isCourseOwner" :courseItems="courseItems"
                                                 :courseId="course.id" :hidecourseitems="course.hidecourseitems"
                                                 v-if="courseItems"/>
                                </div>

                                <div class="course__day course__day--next" v-if="course.hidecoursedays !== true">
                                    <CourseDay :courseDay.sync="courseDayView.next" v-if="courseDayView.next"
                                               :isCourseOwner="isCourseOwner"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="course__storage" v-else>
                        <CourseStorage :storage="courseItems" :course="course" :isCourseOwner="isCourseOwner"/>
                    </div>
                </div>
            </div>
        </div>

        <modal v-if="showcourseModal" class="modal--overlay">
            <template slot="header">
                <h3 class="modal-title">Kurs bearbeiten</h3>
                <md-button class="md-simple md-just-icon md-round modal-default-button"
                           @click="showcourseModal = false">
                    <md-icon>clear</md-icon>
                </md-button>
            </template>

            <template slot="body">
                <tabs id="CoursedetailCourseEdit" :tab-name="courseTabs" plain>
                    <template slot="tab-pane-1">

                        <div class="md-layout">
                            <div class="md-layout-item md-size-100">
                                <md-field>
                                    <label>Titel</label>
                                    <md-input v-model="course.title"></md-input>
                                </md-field>
                            </div>

                            <div class="md-layout-item md-size-100">
                                <div class="ckeditor__wrapper">
                                    <label>Beschreibung</label>
                                    <ckeditor :editor="$ckeditor.editor" v-model="course.description"
                                              :config="$ckeditor.config"></ckeditor>
                                </div>
                            </div>

                            <div class="md-layout-item md-size-100 pt-1">
                                <md-field>
                                    <label for="status">Status</label>
                                    <md-select id="status" v-model="course.status" name="status">
                                        <md-option value="published">Aktiv</md-option>
                                        <md-option value="archived">Archiviert</md-option>
                                    </md-select>
                                </md-field>
                            </div>

                            <template v-if="!isSubcourse">
                                <div class="md-layout-item md-size-50 md-small-size-100 pt-1 text-left">
                                    <md-checkbox id="hidecoursedays" name="hidecoursedays"
                                                 v-model="course.hidecoursedays">
                                        Kurstage
                                        ausblenden
                                    </md-checkbox>
                                </div>

                                <div class="md-layout-item md-size-50 md-small-size-100 pt-1 text-left">
                                    <md-checkbox id="hidecourseitems" name="hidecourseitems"
                                                 v-model="course.hidecourseitems">Kursbausteine
                                        ausblenden
                                    </md-checkbox>
                                </div>

                                <div class="md-layout-item md-size-100 text--left pt-1">
                                    <md-button class="md-warning" @click="duplicateCourse()">Kurs kopieren</md-button>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template slot="tab-pane-2" v-if="!isSubcourse">
                        <div class="md-layout-item md-size-100 pt-1">
                            <md-field>
                                <md-select id="coursevisibility" v-model="course.coursevisibility"
                                           name="coursevisibility">
                                    <md-option value="protected">Geschützt (Berechtigte Gruppen können den Kurs sehen)
                                    </md-option>
                                    <md-option value="private">Privat (nur ich kann den Kurs sehen)</md-option>
                                    <md-option value="public">Öffentlich (alle können den Kurs sehen)</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-100 pt-1">
                            <Select v-model="course.selectGroups" :options="courseAccessSelection.options"
                                    title="Gruppen"/>
                        </div>
                    </template>
                    <template slot="tab-pane-3" v-if="!isSubcourse">
                        <div class="md-layout">
                            <div class="md-layout-item md-size-20"></div>
                            <div class="md-layout-item md-size-60 md-small-size-100">
                                <div class="md-layout">
                                    <div class="md-layout-item md-size-10"></div>
                                    <div class="md-layout-item md-size-80 md-small-size-100">
                                        <md-field
                                                class="formfield--help-text formfield--help-text--visible formfield--help-text--large">
                                            <label>Anzahl Kurstage in der Zukunft anzeigen</label>
                                            <md-input v-model="course.showleadingcoursedays" type="number"></md-input>
                                            <span class="md-helper-text">Gibt an, wieviele Kurstage in der Zukunft einem Kontakt angezeigt werden soll. Besitzer sehen alle Tage. Beispiel: -1 = Alle Tage werden angezeigt. 7 = Alle vergangenen und alle Kurstage der nächsten 7 Tagen werden angezeigt.</span>
                                        </md-field>
                                    </div>
                                    <div class="md-layout-item md-size-100">
                                        <p class="text--title">Kurstage</p>
                                        <v-calendar :attributes="attributes" @dayclick="onDayClick"
                                                    :columns="$screens({ default: 1, lg: 2 })"/>
                                    </div>

                                    <div class="md-layout-item md-size-100">
                                        <p class="text--title">Kurstage verschieben</p>
                                        <p>Verschiebt alle Kurstage um die anzahl Tage ausgehend vom ersten Kurstag</p>
                                        <v-date-picker v-model='moveCourseDaysDate'/>
                                        <div class="text--center">
                                            <md-button class="md-warning" @click="moveCourseDays()">verschieben
                                            </md-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template slot="tab-pane-2" v-if="isSubcourse">
                        <CourseStorageTable :items="courseItems" :course="course" />
                    </template>
                </tabs>
            </template>

            <template slot="footer">
                <div class="display--flex-between">
                    <md-button class="md-info md-simple" @click="showcourseModal = false">Abbrechen</md-button>
                    <md-button class="md-primary" @click="updateCourse()">Speichern</md-button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>

import {factory} from "@/services/factory";
import Mixins from "@/plugins/basicMixins";
import Modal from '@/components/custom/Modal.vue';
import Tabs from "@/components/custom/Tabs.vue";
import CourseDay from "@/components/custom/CourseDay.vue";
import CourseItems from "@/components/custom/CourseItems.vue";
import Select from "@/components/custom/Select.vue";
import CourseStorage from "@/components/custom/CourseStorage.vue";
import CourseStorageTable from "@/components/custom/CourseStorageTable.vue";
import 'swiper/css/swiper.css';
import moment from 'moment';

import "vue-pdf-app/dist/icons/main.css";
import typographyImagesSection from "../components/TypographyImagesSection.vue";

moment.locale('de-ch')

export default {
    components: {Modal, Tabs, Select, CourseDay, CourseItems, CourseStorage, CourseStorageTable},
    mixins: [Mixins.HeaderImage],
    bodyClass: "page--content",
    props: ['user'],
    computed: {
        dates() {
            return this.days.map(day => day.date);
        },
        attributes() {
            return this.dates.map(date => ({
                highlight: true,
                dates: date,
            }));
        },
    },
    data() {
        return {
            handedCourseID: "",
            moveCourseDaysDate: new Date(),
            isCourseOwner: true,
            showcourseModal: false,
            course: null,
            isSubcourse: false,
            courseTabs: [],
            days: [],
            today: moment().startOf('day').format('x'),
            closestDay: {
                id: null,
                difference: null,
                index: null
            },
            courseDayView: {
                currentIndex: null,
                current: null,
                prev: null,
                next: null
            },
            courseItems: null,
            courseAccessSelection: {
                initialized: false,
                options: []
            },
        };
    },
    mounted() {
        this.initialize(this.$route.params.courseid);
    },
    beforeRouteUpdate(to, from, next) {
        this.initialize(to.params.courseid);
        next();
    },
    methods: {
        initialize(handedCourseID) {
            this.handedCourseID = handedCourseID;
            this.getCourse();
        },
        getCourse: function () {
            this.directus.items('course').readOne(this.handedCourseID, {
                fields: [
                    '*',
                    'courseitem.*',
                    'user_groups.*',
                    'user_groups.user_groups_id.*',
                ],
                deep: {
                    courseitem: {
                        _sort: "date",
                        _filter: {
                            status: 'published',
                            type: 'dayitem'
                        },
                        courseitemcontent: {
                            _filter: {
                                status: 'published',
                            },
                            files: {
                                _filter: {
                                    status: 'published'
                                }
                            }
                        }
                    },
                    user_groups: {
                        _filter: {
                            status: 'published'
                        }
                    }
                }
            }).then(response => {
                this.course = response;
                this.isCourseOwner = this.course.owner === this.$user.user.id
                this.isSubcourse = this.course.parentcourseitem.length > 0
                this.getCourseItems();

                if (this.isSubcourse === true) {
                    this.courseTabs = ['Allgemein', 'Inhalt'];
                } else {
                    this.courseTabs = ['Allgemein', 'Berechtigungen', 'Kurstage'];
                    let leadingDate = new Date();
                    leadingDate.setDate(leadingDate.getDate() + this.course.showleadingcoursedays);
                    var toRemoveDates = [];

                    this.course.courseitem.forEach((courseitem, index) => {
                        if (this.isCourseOwner === false && this.course.showleadingcoursedays >= 0 && courseitem.date > leadingDate.getTime()) {
                            toRemoveDates.push(index);
                            return;
                        }

                        courseitem.dateDefaultString = moment(courseitem.date)._d;
                        let time_difference = Math.abs(this.today - courseitem.date)

                        if (this.closestDay.difference === null || time_difference < this.closestDay.difference) {
                            this.closestDay = {
                                id: courseitem.id,
                                difference: time_difference
                            }
                        }

                        this.days.push({
                            id: moment(courseitem.date).format('YYYY-MM-DD'),
                            date: courseitem.dateDefaultString,
                            timestamp: courseitem.date
                        })
                    });

                    if (this.isCourseOwner === false && this.course.showleadingcoursedays >= 0) {
                        this.course.courseitem = this.course.courseitem.filter(function (value, index) {
                            return toRemoveDates.indexOf(index) === -1;
                        })
                    }

                    this.course.selectGroups = [];
                    this.course.user_groups.forEach(user_groups_course => {
                        this.course.selectGroups.push(user_groups_course.user_groups_id)
                    })

                    this.setCourseDayView();
                }
            }).catch(error => {
                console.log(error);
            })
        },
        setCourseDayView() {
            if (this.courseDayView.currentIndex === null) {
                this.courseDayView.currentIndex = this.course.courseitem.findIndex(day => day.id === this.closestDay.id);
                setTimeout(() => {
                    this.$refs.coursePagination.querySelector('.course__pagination-button--active').scrollIntoView();
                }, 500)
            }

            if (this.courseDayView.currentIndex < 0) {
                this.courseDayView.currentIndex = 0;
            }

            if (this.courseDayView.currentIndex > this.course.courseitem.length - 1) {
                this.courseDayView.currentIndex = this.course.courseitem.length - 1;
            }

            this.courseDayView.prev = this.course.courseitem[this.courseDayView.currentIndex - 1];
            this.courseDayView.current = this.course.courseitem[this.courseDayView.currentIndex];
            this.courseDayView.next = this.course.courseitem[this.courseDayView.currentIndex + 1];
        },
        getCourseItems() {
            let query = {
                sort: 'sort',
                limit: -1,
                filter: {
                    status: 'published',
                    type: 'courseitem',
                    course: this.handedCourseID
                }
            }

            if (this.isSubcourse === true) {
                query = {
                    sort: 'sort',
                    limit: -1,
                    filter: {
                        status: 'published',
                        type: 'storage',
                        course: this.course.id
                    },
                    deep: {
                        courseitemcontent: {
                            _sort: "sort",
                            _filter: {
                                status: 'published'
                            },
                        },
                    },
                    fields: [
                        '*',
                        'courseitemcontent.*',
                        'courseitemcontent.files.*',
                        'courseitemcontent.files.directus_files_id',
                        'courseitemcontent.files.directus_files_id.*'
                    ]
                }
            }

            this.directus.items('courseitem').readByQuery(query).then(response => {
                this.courseItems = response.data
            }).catch(error => {
                console.log(error);
            })
        },
        onDayClick(day) {
            const idx = this.days.findIndex(d => d.id === day.id);
            if (idx >= 0) {
                this.days.splice(idx, 1);
            } else {
                this.days.push({
                    id: day.id,
                    date: day.date,
                    timestamp: moment(day.id).format('x')
                });
            }
        },
        updateCourse() {
            // Update Course
            let promises = []
            promises.push(this.directus.items('course').updateOne(this.course.id, {
                title: this.course.title,
                description: this.course.description,
                showleadingcoursedays: this.course.showleadingcoursedays,
                status: this.course.status,
                coursevisibility: this.course.coursevisibility,
                parent: this.course.parent,
                hidecoursedays: this.course.hidecoursedays,
                hidecourseitems: this.course.hidecourseitems
            }))

            if (!this.isSubcourse) {
                //Add or remove courseitems
                promises.push(factory.updateForeignTable(
                    this.days,
                    'courseitem',
                    {course: this.course.id, status: 'published', type: 'dayitem'},
                    'timestamp',
                    'date',
                    [{fieldName: 'status', value: 'published'}, {fieldName: 'date', key: 'timestamp'}, {
                        fieldName: 'course',
                        value: this.course.id
                    }]
                ));

                // Update GROUP - COURSES
                promises.push(factory.updateForeignTable(
                    this.course.selectGroups,
                    'user_groups_course',
                    {course_id: this.course.id, status: 'published'},
                    'id',
                    'user_groups_id',
                    [{fieldName: 'status', value: 'published'}, {fieldName: 'user_groups_id', key: 'id'}, {
                        fieldName: 'course_id',
                        value: this.course.id
                    }]
                ));
            }

            Promise.all(promises).then(results => {
                let innerPromises = [];

                if (results[1]) {
                    results[1].forEach(tmp => innerPromises.push(tmp));
                }
                if (results[2]) {
                    results[2].forEach(tmp => innerPromises.push(tmp));
                }

                if (innerPromises.length > 0) {
                    Promise.all(innerPromises).then(() => {
                        this.showcourseModal = false;
                        this.getCourse();
                    })
                } else {
                    this.showcourseModal = false;
                    this.getCourse();
                }
            })
        },
        getFileType(filetype, type) {
            if (type === 'extension') {
                return filetype.split('/')[1];
            }
            return filetype.split('/')[0];
        },
        openCourseModal() {
            if (this.courseAccessSelection.initalized !== true) {
                this.directus.items('user_groups').readByQuery({
                    owner: this.$user.user.id,
                    status: 'published',
                    limit: -1
                }).then(response => {
                    this.courseAccessSelection.options = response.data;
                    this.courseAccessSelection.initalized = true;
                    return true;
                })
            }
            this.showcourseModal = true;
        },
        courseNavigation(action, index = 0) {
            switch (action) {
                case 'index':
                    this.courseDayView.currentIndex = index;
                    this.setCourseDayView();
                    break;
                case 'prev':
                    this.courseDayView.currentIndex = this.courseDayView.currentIndex - 1;
                    this.setCourseDayView();
                    break;
                case 'next':
                    this.courseDayView.currentIndex = this.courseDayView.currentIndex + 1;
                    this.setCourseDayView();
                    break;
            }
        },
        duplicateCourseItemsInner(courseItem, newCourse, subCourseId = null) {
            return new Promise(courseResolve => {
                this.directus.items('courseitem').createOne({
                    status: courseItem.status,
                    date: courseItem.date,
                    course: newCourse.id,
                    title: courseItem.title,
                    type: courseItem.type,
                    content: courseItem.content,
                    sort: courseItem.sort,
                    subcourse: subCourseId
                }).then(newCourseItem => {
                    this.directus.items('courseitemcontent').readByQuery({
                        limit: -1,
                        filter: {
                            status: 'published',
                            courseitem: courseItem.id
                        },
                        fields: [
                            '*',
                            'files.*',
                        ],
                        deep: {
                            files: {
                                _filter: {
                                    status: 'published'
                                }
                            }
                        }
                    }).then(courseItemContents => {
                        let courseItemContentPromises = []
                        courseItemContents.data.forEach(courseItemContent => {
                            courseItemContentPromises.push(new Promise(courseItemContentResolve => {
                                this.directus.items('courseitemcontent').createOne({
                                    status: courseItemContent.status,
                                    courseitem: newCourseItem.id,
                                    title: courseItemContent.title,
                                    description: courseItemContent.description,
                                    sort: courseItemContent.sort
                                }).then(newCourseItemContent => {
                                    if (courseItemContent.files) {
                                        let filePromises = [];
                                        courseItemContent.files.forEach(file => {
                                            filePromises.push(this.directus.items('courseitemcontent_files').createOne({
                                                status: file.status,
                                                sort: file.sort,
                                                courseitemcontent_id: newCourseItemContent.id,
                                                directus_files_id: file.directus_files_id
                                            }))
                                        })

                                        Promise.all(filePromises).then(() => {
                                            courseItemContentResolve();
                                        })
                                    } else {
                                        courseItemContentResolve();
                                    }
                                })
                            }))
                        })
                        Promise.all(courseItemContentPromises).then(() => {
                            courseResolve();
                        })
                    })
                })
            });
        },
        duplicateCourseItems(oldCourseId, newCourse, forSubCourse = false) {
            this.directus.items('courseitem').readByQuery({
                filter: {
                    status: 'published',
                    course: oldCourseId
                },
                limit: -1
            }).then(courseItems => {
                let coursePromises = [];
                courseItems.data.forEach(courseItem => {
                    if(courseItem.subcourse !== null) {
                        this.directus.items('course').readOne(courseItem.subcourse).then(course => {
                            this.duplicateCourseInner(course.title, course).then(newSubCourse => {
                                coursePromises = coursePromises.concat(this.duplicateCourseItems(course.id, newSubCourse, true))
                                coursePromises.push(this.duplicateCourseItemsInner(courseItem, newCourse, newSubCourse.id));
                            });
                        })
                    } else {
                        coursePromises.push(this.duplicateCourseItemsInner(courseItem, newCourse));
                    }
                });

                if (forSubCourse) {
                    return coursePromises;
                }
                console.log(coursePromises);
                Promise.all(coursePromises).then(() => {
                    this.showcourseModal = false;
                    this.$parent.alertManager('Der Kurs wurde erfolgreich kopiert.', 'success-message');
                })
            }).catch(error => {
                console.log(error);
            })
        },

        duplicateCourseInner(newName, course) {
            return this.directus.items('course').createOne({
                title: newName,
                description: course.description,
                showleadingcoursedays: course.showleadingcoursedays,
                status: course.status,
                coursevisibility: course.coursevisibility,
                owner: $user.user.id
            })
        },
        duplicateCourse() {
            let newName = prompt("neuen Namen eingeben", this.course.title);
            if (newName && newName.length > 0) {
                this.duplicateCourseInner(newName, this.course).then(newCourse => {
                    this.duplicateCourseItems(this.handedCourseID, newCourse);
                })
            }
        },
        moveCourseDays() {
            let daysDiff = moment(this.moveCourseDaysDate).startOf('day').diff(moment(this.course.courseitem[0].date).startOf('day'), 'days');

            if (daysDiff === 0) {
                this.showcourseModal = false;
                this.moveCourseDaysDate = new Date();
                return;
            }

            let promises = [];
            this.course.courseitem.forEach(item => {
                promises.push(this.directus.items('courseitem').updateOne(item.id, {
                    date: moment(item.date).add(daysDiff, 'days').format('x')
                }));
            });

            Promise.all(promises).then(() => {
                this.showcourseModal = false;
                this.moveCourseDaysDate = new Date();
                this.getCourse();
            });
        },
        onDrop(e, courseDay) {
            let promises = [];
            let itemId = e.dataTransfer.getData('itemId');
            let parentId = e.dataTransfer.getData('parentId');

            if (!itemId) {
                return;
                //@todo error
            }

            //if dropped in diffrent courseday
            if (itemId !== courseDay.id) {
                promises.push(this.directus.items('courseitemcontent').updateOne(parentId, {
                    courseitem: courseDay.id
                }));
            }

            var promises2 = factory.onDrop(e, 'courseitemcontent');

            Promise.all([...promises, ...promises2]).then(response => {
                this.getCourse();
            });
        },
    }
};
</script>
