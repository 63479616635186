<template>
  <md-card
      class="md-card-blog"
      :class="{
      'md-card-plain': cardPlain,
      'md-card-blogpost': type === 'horizontal'
    }"
  >
    <template>
      <a href="javascript:void(0)">
        <md-card-header :class="cardImage ? 'md-card-header-image' : 'md-card--no-header-image'" :shadow-normal="shadowNormal" :no-colored-shadow="noColoredShadow">
          <img class="img" :src="cardImage" v-if="cardImage">
          <div v-if="$slots.cardTitleHeader" class="card-title">
            <slot name="cardTitleHeader"/>
          </div>
          <div v-if="!noColoredShadow && !shadowNormal" class="colored-shadow" :style="shadowImageBlog(cardImage)"/>
        </md-card-header>

        <md-card-content v-if="$slots.cardContent">
          <slot name="cardContent"/>
        </md-card-content>

        <md-card-actions v-if="$slots.cardAction">
          <slot name="cardAction"/>
        </md-card-actions>
      </a>
    </template>
  </md-card>
</template>

<script>
export default {
  name: "SimpleCard",
  props: {
    type: String,
    reverseRow: Boolean,
    cardImage: String,
    shadowNormal: {
      type: Boolean,
      default: false
    },
    cardPlain: Boolean,
    noColoredShadow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    shadowImageBlog(image) {
      return {
        backgroundImage: `url(${image})`,
        opacity: 1
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.reverse-row {
  flex-direction: row-reverse;
}
</style>
