<!-- eslint-disable vue/no-mutating-props -->

<template>
    <div class="courseitem__wrapper">
        <div class="courseitem text--small" :class="{'courseitem--owner' : isCourseOwner}">
            <div class="courseitem__title">
                <b>{{ courseItem.title }}</b>

                <div v-if="isCourseOwner === true" class="draganddrop__trigger"
                     :draggable="isCourseOwner ? true : false"
                     @drag="handleDrag($event, courseItem)" @dragstart="startDrag($event, courseItem)">
                    <md-icon>drag_indicator</md-icon>
                </div>
            </div>
            <p class="courseitem__content" v-html="courseItem.content"></p>

            <div class="courseitem__button" @click="showCourseItemModal=true">
                <md-button class="md-primary md-just-icon md-round md-sm" v-if="courseItem.subcourse"
                           @click.stop="openSubCourse()">
                    <md-icon>folder_open</md-icon>
                </md-button>
                <md-button class="md-primary md-just-icon md-round md-sm">
                    <md-icon v-if="isCourseOwner===true">edit</md-icon>
                    <md-icon v-else>ads_click</md-icon>
                </md-button>
            </div>
        </div>
        <modal v-if="showCourseItemModal" class="modal--overlay" text-align="text-left">
            <template slot="header">
                <template v-if="isCourseOwner">
                    <h4 class="modal-title" v-if="courseItem.id">Inhalt bearbeiten</h4>
                    <h4 class="modal-title" v-else>Inhalt hinzufügen</h4>
                </template>
                <template v-else>
                    <h4 class="modal-title">{{ courseItem.title }}</h4>
                </template>
                <md-button class="md-simple md-just-icon md-round modal-default-button"
                           @click.stop="showCourseItemModal=false;getCourseItems();">
                    <md-icon>clear</md-icon>
                </md-button>
            </template>

            <template slot="body">
                <div class="md-layout">
                    <template v-if="isCourseOwner">
                        <div class="md-layout-item md-size-70 md-small-size-100">
                            <md-field>
                                <label>Titel</label>
                                <md-input v-model="courseItem.title"/>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-30 md-small-size-100">
                            <md-field>
                                <label>Sortierung</label>
                                <md-input v-model="courseItem.sort"/>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-100">
                            <div class="ckeditor__wrapper">
                                <label>Beschreibung</label>
                                <ckeditor :editor="$ckeditor.editor" v-model="courseItem.content"
                                          :config="$ckeditor.config"></ckeditor>
                            </div>
                        </div>
                        <div class="md-layout-item md-size-30 md-small-size-100 pt-1">
                            <md-button v-if="courseItem.subcourse" class="md-primary" @click.stop="openSubCourse()">
                                Ablage öffnen
                            </md-button>
                            <md-button v-else class="md-info" @click.stop="addSubCoruse(courseItem)">
                                Ablage erstellen
                            </md-button>
                        </div>
                    </template>
                    <template v-else>
                        <div class="courseitem__modal-content text--html" v-html="courseItem.content"></div>
                    </template>
                </div>
            </template>

            <template slot="footer">
                <div class="display--flex-between">
                    <md-button class="md-info md-simple" @click.stop="showCourseItemModal=false;getCourseItems();">
                        Abbrechen
                    </md-button>
                    <template v-if="isCourseOwner">
                        <md-button class="md-danger" @click.stop="deleteItem()">Löschen</md-button>
                        <md-button class="md-primary" @click.stop="updateCourseItem()">Speichern</md-button>
                    </template>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>

/* eslint-disable vue/no-mutating-props */

import Modal from '@/components/custom/Modal.vue';
import {factory} from "../../services/factory";

export default {
    name: "CourseItem",
    components: {Modal},
    props: {
        courseItem: {
            type: Object,
            default: null
        },
        isCourseOwner: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showCourseItemModal: false,
            showFilesModal: false,
            fileUpload: null,
            sortTimeout: null,
            origin: window.location.origin
        }
    },
    mounted() {
        if (this.courseItem && this.courseItem.newItem && this.courseItem.newItem === true) {
            this.showCourseItemModal = true;
            this.courseItem.newItem = false;
        }
    },
    methods: {
        getCourseItems() {
            this.$parent.getCourseItems();
        },
        deleteItem() {
            let confirmResult = confirm("Sicher dass der Kontakt entfernt werden soll?");
            if (this.courseItem.id && confirmResult === true) {
                this.directus.items('courseitem').updateOne(this.courseItem.id, {
                    status: 'archived'
                }).then(() => {
                    this.getCourseItems();
                    this.showCourseItemModal = false;
                })
            }
        },
        updateCourseItem() {
            this.directus.items('courseitem').updateOne(this.courseItem.id, {
                title: this.courseItem.title,
                sort: this.courseItem.sort,
                content: this.courseItem.content
            }).then(() => {
                this.showCourseItemModal = false;
                this.getCourseItems();
            })
        },
        startDrag(e, item) {
            factory.startDrag(e, item.id);
        },
        handleDrag(e) {
            factory.handleDrag(e);
        },
        openSubCourse() {
            this.$router.push({params: {courseid: this.courseItem.subcourse}})
        },
        addSubCoruse(courseItem) {
            if (!courseItem.subcourse && courseItem.id) {
                this.directus.items('courseitem').updateOne(courseItem.id, {
                    "subcourse": {
                        owner: this.$user.user.id
                    }
                }).then(() => {
                    this.getCourseItems();
                })
            }
        }
    }
};
</script>