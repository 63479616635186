<template>
  <div class="wrapper">
    <notifications ref="notifications"/>
    <div class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="login__wrapper">
            <form @submit="mailRegister">
              <login-card header-color="green">
                <md-button slot="buttons" class="md-simple md-white" disabled>
                  Registrierung abschliessen
                </md-button>

                <md-field slot="inputs" class="md-form-group" id="password">
                  <md-icon>lock_outline</md-icon>
                  <label>Passwort</label>
                  <md-input v-model="user.password" type="password" required/>
                  <span class="md-error">Bitte Ihr Passwort eingeben</span>
                </md-field>

                <md-field slot="inputs" class="md-form-group" id="password-repeat">
                  <md-icon>lock_outline</md-icon>
                  <label>Passwort wiederholen</label>
                  <md-input v-model="user.passwordRepeat" type="password" required/>
                  <span class="md-error">Bitte Ihr Passwort wiederholen</span>
                </md-field>

                <md-button type="submit" slot="footer" class="md-simple md-success md-lg">Registrierung abschliessen</md-button>
              </login-card>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {LoginCard} from "@/components";
import {Notifications} from "@/components";
import Mixins from "@/plugins/basicMixins";
import {userService} from "@/services/user.service";

export default {
  components: {
    LoginCard,
    Notifications
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "login-page",
  data() {
    return {
      image: require("@/assets/img/profile_city.jpg"),
      firstname: null,
      fields: {},
      errorClass: 'md-invalid',
      hasError: false,
      user: {
        password: null,
        passwordRepeat: null,
        name: null,
        firstname: null,
        status: 'active'
      },
      notifications: [],
    };
  },
  mounted: function () {
    this.fields['password'] = document.getElementById('password')
    this.fields['passwordRepeat'] = document.getElementById('password-repeat')
  },
  methods: {
    mailRegister: function (e) {
      e.preventDefault();
      this.hasError = false;

      for (let key in this.fields) {
        let field = this.fields[key]
        let input = field.querySelector('input')

        if (input.value === '') {
          this.handleFieldError(field, 'add');
          this.hasError = true;
        } else {
          this.handleFieldError(field);
        }

        if (key === 'passwordRepeat') {
          if (input.value !== this.fields['password'].querySelector('input').value) {
            this.handleFieldError(field, 'add');
            this.hasError = true;
          }
        }
      }

      if (this.hasError === false) {
        let token = this.$route.query.token
        if(!token) {
          token = window.location.search.split('=')[1]
        }

        userService.acceptInvite(token, this.user.password).then(() => {
          this.$parent.alertManager('Die Registrierung wurde erfolgreich durchgeführt.', 'success-message');
          this.$router.push('/login');
        }).catch(error => {
          this.$parent.alertManager('Beim Registrieren ist leider ein Fehler aufgetreten. Bitte versuche es erneut.', 'error-message')
          console.log(error);
        })

          /*
          userService.finishRegister(this.user).then(response => {
            if (response.success === true) {
              this.$router.push('/')
            } else {
              this.$refs.notifications.addNotification('loginError', 'danger', 'Registrierung Fehlgeschlagen', 'Ihre Registrierung ist fehlgeschlagen, bitte versuchen Sie es erneut.')
            }
          })

           */
      }
    },
    handleFieldError: function (field, action = 'remove') {
      if (action === 'remove') {
        field.classList.remove(this.errorClass)
      } else if (action === 'add') {
        field.classList.add(this.errorClass)
      }
    }
  }
};
</script>

<style lang="css"></style>
