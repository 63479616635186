<template>
  <md-toolbar
      id="toolbar"
      md-elevation="0"
      class="md-fixed"
      :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent
    }"
      :color-on-scroll="colorOnScroll"
  >
    <div class="beta" title="Beta Version" :class="context.classes">{{ context.label }}</div>
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <a href="/">
          <h3 class="md-title">
            {{ brand }}
          </h3>
        </a>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
            class="md-just-icon md-simple md-toolbar-toggle"
            :class="{ toggled: toggledClass }"
            @click="toggleNavbarMobile()"
        >
          <span class="icon-bar"/>
          <span class="icon-bar"/>
          <span class="icon-bar"/>
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>
            <md-list>
              <template v-if="isUserLoggedIn">
                <li class="md-list-item">
                  <a href="/#/courses" class="md-list-item-router md-list-item-container md-button-clean">
                    <div class="md-list-item-content">
                      <md-button slot="title" class="md-button md-button-link md-white md-simple">
                        <md-icon>book</md-icon>
                        <p>Kurse</p>
                      </md-button>
                    </div>
                  </a>
                </li>

                <li class="md-list-item">
                  <a href="/#/users" class="md-list-item-router md-list-item-container md-button-clean">
                    <div class="md-list-item-content">
                      <md-button slot="title" class="md-button md-button-link md-white md-simple">
                        <md-icon>people</md-icon>
                        <p>Kontakte</p>
                        <span class="button__counter" v-if="openUserRequests > 0">{{openUserRequests}}</span>
                      </md-button>
                    </div>
                  </a>
                </li>

                <li class="md-list-item">
                  <a href="/#/groups" class="md-list-item-router md-list-item-container md-button-clean">
                    <div class="md-list-item-content">
                      <md-button slot="title" class="md-button md-button-link md-white md-simple">
                        <md-icon>groups</md-icon>
                        <p>Gruppen</p>
                      </md-button>
                    </div>
                  </a>
                </li>

                <li class="md-list-item">
                  <a href="/#/editprofile" class="md-list-item-router md-list-item-container md-button-clean">
                    <div class="md-list-item-content">
                      <md-button slot="title" class="md-button md-button-link md-white md-simple">
                        <md-icon>edit</md-icon>
                        <p>Mein Profil</p>
                      </md-button>
                    </div>
                  </a>
                </li>

              </template>

              <li class="md-list-item" v-if="isUserLoggedIn === false">
                <a href="/#/login" class="md-list-item-router md-list-item-container md-button-clean">
                  <div class="md-list-item-content">
                    <md-button slot="title" class="md-button md-button-link md-white md-simple">
                      <md-icon>person_outline</md-icon>
                      <p>Login</p>
                    </md-button>
                  </div>
                </a>
              </li>

              <li class="md-list-item" v-else>
                <a href="#" class="md-list-item-router md-list-item-container md-button-clean" @click="userLogout">
                  <div class="md-list-item-content">
                    <md-button slot="title" class="md-button md-button-link md-white md-simple">
                      <md-icon>logout</md-icon>
                      <p>Logout</p>
                    </md-button>
                  </div>
                </a>
              </li>

              <li class="md-list-item" v-if="isUserLoggedIn === false">
                <a href="/#/register" class="md-list-item-router md-list-item-container md-button-clean">
                  <div class="md-list-item-content">
                    <md-button slot="title" class="md-button md-button-link md-white md-simple">
                      <md-icon>person_add</md-icon>
                      <p>Registrieren</p>
                    </md-button>
                  </div>
                </a>
              </li>

              <li v-if="showDownload && isUserAdmin" class="md-list-item">
                <a href="javascript:void(0)"
                   class="md-list-item-router md-list-item-container md-button-clean dropdown">
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                          slot="title"
                          class="md-button md-button-link md-white md-simple dropdown-toggle"
                          data-toggle="dropdown"
                      >
                        <i class="material-icons">apps</i>
                        <p>Components</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li>
                          <a href="#/presentation">
                            <i class="material-icons">layers</i>
                            Presentation
                          </a>
                        </li>
                        <li>
                          <a href="#/">
                            <i class="material-icons">line_style</i>
                            All Components
                          </a>
                        </li>
                        <li>
                          <a :href="docs_link">
                            <i class="material-icons">content_paste</i>
                            Documentation
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>
              <li
                  v-if="!showDownload && isUserAdmin"
                  class="md-list-item"
              >
                <a
                    href="javascript:void(0)"
                    class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                          slot="title"
                          class="md-button md-button-link md-white md-simple dropdown-toggle"
                          data-toggle="dropdown"
                      >
                        <i class="material-icons">apps</i>
                        <p>Components</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li>
                          <a href="#/presentation">
                            <i class="material-icons">layers</i>
                            Presentation
                          </a>
                        </li>
                        <li>
                          <a :href="docs_link">
                            <i class="material-icons">content_paste</i>
                            Documentation
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>

              <li class="md-list-item" v-if="isUserAdmin">
                <a
                    href="javascript:void(0)"
                    class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                          slot="title"
                          class="md-button md-button-link md-white md-simple dropdown-toggle"
                          data-toggle="dropdown"
                      >
                        <i class="material-icons">view_day</i>
                        <p>Sections</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li
                            v-for="li in linksSections"
                            :key="li.name"
                        >
                          <a
                              :href="'#/sections#' + li.name"
                              @click="
                              () => {
                                NavbarStore.showNavbar = false;
                                toggledClass = false;
                              }
                            "
                          >
                            <i class="material-icons">{{ li.icon }}</i>
                            {{
                              li.name.charAt(0).toUpperCase() + li.name.slice(1)
                            }}
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>

              <li class="md-list-item" v-if="isUserAdmin">
                <a
                    href="javascript:void(0)"
                    class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                          slot="title"
                          class="md-button md-button-link md-white md-simple dropdown-toggle"
                          data-toggle="dropdown"
                      >
                        <i class="material-icons">view_carousel</i>
                        <p>Examples</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li
                            v-for="li in linksExamples"
                            :key="li.name"
                        >
                          <a :href="'#/' + li.href">
                            <i class="material-icons">{{ li.icon }}</i>
                            {{ li.name }}
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import {userService} from "@/services/user.service";

let resizeTimeout;

function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

import MobileMenu from "@/layout/MobileMenu";
import {factory} from "@/services/factory";

export default {
  name: 'MainNavbar',
  components: {
    MobileMenu
  },
  props: {
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
          "dark"
        ].includes(value);
      }
    },
    colorOnScroll: {
      type: Number,
      default: 0
    },
    navbarTransparent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      context: {
        label: "Beta",
        classes: ""
      },
      docs_link:
          "https://demos.creative-tim.com/vue-material-kit-pro/documentation/",
      extraNavClasses: "",
      toggledClass: false,
      brand: "symmle",
      linksSections: [
        {name: "headers", icon: "dns"},
        {name: "features", icon: "build"},
        {name: "blogs", icon: "list"},
        {name: "teams", icon: "people"},
        {name: "projects", icon: "assignment"},
        {name: "pricing", icon: "monetization_on"},
        {name: "testimonials", icon: "chat"},
        {name: "contacts", icon: "call"}
      ],
      linksExamples: [
        {name: "About Us", href: "about-us", icon: "account_balance"},
        {name: "Blog Post", href: "blog-post", icon: "art_track"},
        {name: "Blog Posts", href: "blog-posts", icon: "view_quilt"},
        {name: "Contact Us", href: "contact-us", icon: "location_on"},
        {name: "Landing Page", href: "landing-page", icon: "view_day"},
        {name: "Login Page", href: "login-page", icon: "fingerprint"},
        {name: "Pricing Page", href: "pricing-page", icon: "attach_money"},
        {
          name: "Shopping Cart",
          href: "shopping-cart",
          icon: "shopping_basket"
        },
        {name: "Ecommerce Page", href: "ecommerce-page", icon: "store"},
        {name: "Product Page", href: "product-page", icon: "shopping_cart"},
        {name: "Profile Page", href: "profile-page", icon: "account_circle"},
        {name: "Signup Page", href: "signup-page", icon: "person_add"},
        {name: "Error Page", href: "error-page", icon: "error"}
      ],
      user: null,
      isUserLoggedIn: false,
      isUserAdmin: false,
      openUserRequests: 0
    };
  },
  computed: {
    showDownload() {
      const excludedRoutes = ["index"];
      return excludedRoutes.every(r => r !== this.$route.name);
    },
  },
  mounted() {
    this.getUserData();
    this.setOpenUserRequests();
    this.setContext();
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    setContext() {
      switch (window.location.hostname){
        case "localhost":
          this.context = {
            label: "Local",
            classes: "context--local"
          }
          break;
        case "dev.symmle.ch":
          this.context = {
            label: "Dev",
            classes: "context--dev"
          }
          break;
      }
    },
    setOpenUserRequests() {
      this.openUserRequests = factory.getOpenUserRequests();
    },
    getUserData() {
      document.addEventListener("scroll", this.scrollListener);
      let getUserResponse = userService.getUser();
      this.user = getUserResponse.user;
      this.isUserLoggedIn = getUserResponse.isLoggedIn;
      this.isUserAdmin = getUserResponse.isAdmin;
    },
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    handleScroll() {
      let scrollValue =
          document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("toolbar");
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `md-${this.type}`;
        navbarColor.classList.remove("md-transparent");
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("md-transparent");
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    },
    userLogout() {
      userService.logout().then(response => {
        if (response === true) {
          this.getUserData();
          if (this.$router.history.current.path !== '/login') {
            this.$router.push('/login')
          }
        }
      })
    }
  }
};
</script>
