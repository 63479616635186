<template>
  <div class="card__wrapper" @click.stop="showDetailPage(user.id)">
    <md-card class="card--user" :class="{'card--disabled' : disabled}">
      <div class="card__avatar">
        <img class="card__image" :src="userImage" alt="Person Poster" v-if="userImage">
        <md-icon v-else>account_circle</md-icon>
      </div>
      <md-card-content>
        <div class="card__body">
          <h2 class="card-category" v-if="user.title">{{ user.title }}</h2>
          <h1 class="card-title">{{ user.first_name }} {{ user.last_name }}</h1>
          <h3 class="card-place text-muted" v-if="user.location">{{ user.location }}</h3>
          <div class="card-tags" v-if="user.tags">
            <h4 class="card-tags__title">Interessen</h4>
            <div class="card-tags__list">
              <span class="card-tags__item text-muted" v-for="tag in user.tags" v-bind:key="tag.id">{{ tag }}</span>
            </div>
          </div>
          <p class="card-description" v-if="user.description">{{ user.description | truncate(100, '...') }}</p>

        </div>
      </md-card-content>

      <md-card-actions v-if="$slots.cardAction" class="justify-content-center">
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>

import {userService} from "@/services/user.service";

export default {
  name: "UserCard",
  mounted() {
    this.setImage();
  },
  data() {
    return {
      userImage: '',
    }
  },
  props: {
    user: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setImage() {
      if(this.user.avatar) {
        userService.refreshAuth().then(response => {
           this.userImage = this.$filePaths.before + this.user.avatar + this.$filePaths.after + response + '&fit=cover&width=120&height=120&quality=95'
        });
      } else {
        return require("@/assets/img/placeholder.jpg");
      }
    },
    showDetailPage(userid) {
      this.$router.push({name: 'userdetail', params: {userid: userid}})
    },
  }
};
</script>