<!-- eslint-disable vue/no-mutating-props -->

<template>
    <div class="coursedaydayitem text--small" @click.stop="isCourseOwner ? showCourseDayDayItemModal=true : null"
         :id="courseDayDayItem.id" :data-sort="courseDayDayItem.sort" v-on:drop="onFileDrop($event)"
         data-draganddrop-item>
        <div class="coursedaydayitem__title">
            <b>{{ courseDayDayItem.title }}</b>

            <div v-if="isCourseOwner === true" class="draganddrop__trigger" :draggable="isCourseOwner ? true : false"
                 @drag="handleDrag($event, courseDayDayItem)" @dragstart="startDrag($event, courseDayDayItem)">
                <md-icon>drag_indicator</md-icon>
            </div>
        </div>

        <p v-html="courseDayDayItem.description"></p>
        <div class="coursedaydayitem__files">
            <div class="coursedaydayitem__file" v-for="file in courseDayDayItem.files" :key="file.id">
                <template v-if="file.directus_files_id">
                    <a href="#" @click.stop="handleFileClick($event, file.directus_files_id)"
                       class="coursedaydayitem__file-link">
                        <template v-if="getFileType(file.directus_files_id.type) === 'image'">
                            <img
                                    :src="$filePaths.before + file.directus_files_id.filename_disk + $filePaths.after + $filePaths.initToken + $filePaths.smallImageAddition"/>
                        </template>
                        <template v-else-if="['application'].includes(getFileType(file.directus_files_id.type))">
                            <img class="coursedaydayitem__icon"
                                 :src="require('@/assets/img/icons/file-'+getFileType(file.directus_files_id.type, 'extension')+'.svg')"/>
                        </template>
                        <template v-else-if="['audio'].includes(getFileType(file.directus_files_id.type))">
                            <img class="coursedaydayitem__icon"
                                 :src="require('@/assets/img/icons/file-audio.svg')"/>
                        </template>
                        <template v-else-if="['video'].includes(getFileType(file.directus_files_id.type))">
                            <img class="coursedaydayitem__icon"
                                 :src="require('@/assets/img/icons/file-video.svg')"/>
                        </template>
                        <span class="coursedaydayitem__file-title">{{ file.directus_files_id.title }}</span>
                    </a>
                </template>
            </div>
        </div>

        <modal v-if="showCourseDayDayItemModal" class="modal--overlay">
            <template slot="header">
                <h4 class="modal-title" v-if="courseDayDayItem.id">Inhalt bearbeiten</h4>
                <h4 class="modal-title" v-else>Inhalt hinzufügen</h4>
                <md-button class="md-simple md-just-icon md-round modal-default-button"
                           @click.stop="showCourseDayDayItemModal=false;getCourseItemContent();">
                    <md-icon>clear</md-icon>
                </md-button>
            </template>

            <template slot="body">
                <div class="md-layout">
                    <div class="md-layout-item md-size-100 md-small-size-100">
                        <tabs :tab-active="1" :tab-name="['Text', 'Hochladen']" :nav-pills-icons="true"
                              :tab-icon="['text_snippet', 'upload']" pillsAlign="center" plain>
                            <template slot="tab-pane-1">
                                <div class="md-layout">
                                    <div class="md-layout-item md-size-70 md-small-size-100">
                                        <md-field>
                                            <label>Titel</label>
                                            <md-input v-model="courseDayDayItem.title"/>
                                        </md-field>
                                    </div>
                                    <div class="md-layout-item md-size-30 md-small-size-100">
                                        <md-field>
                                            <label>Sortierung</label>
                                            <md-input v-model="courseDayDayItem.sort"/>
                                        </md-field>
                                    </div>
                                    <div class="md-layout-item md-size-100">
                                        <div class="ckeditor__wrapper">
                                            <label>Beschreibung</label>
                                            <ckeditor :editor="$ckeditor.editor" v-model="courseDayDayItem.description"
                                                      :config="$ckeditor.config"></ckeditor>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template slot="tab-pane-2">
                                <div class="md-layout">
                                    <div class="md-layout-item md-size-100">
                                        <div class="md-table">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th colspan="3" style="text-align: left">Dateien</th>
                                                </tr>
                                                </thead>
                                                <tbody data-draganddrop @drop="onDrop($event, courseDayDayItem)"
                                                       @dragover.prevent
                                                       @dragenter.prevent>
                                                <template v-for="file in courseDayDayItem.files">
                                                    <template v-if="file.directus_files_id">
                                                        <tr class="md-table-row" v-if="file.status !== 'archived'"
                                                            :key="file.id" :id="file.id" :data-sort="file.sort"
                                                            data-draganddrop-item>
                                                            <td style="width: 40px;">
                                <span :draggable="isCourseOwner ? true : false" @drag="handleDrag($event, file)"
                                      @dragstart="startDrag($event, file)">
                                  <md-icon>drag_indicator</md-icon>
                                </span>
                                                            </td>
                                                            <td>
                                                                <div class="display--flex-between display--flex-wrap">
                                                                    <input type="text" class="formfield--hidden"
                                                                           v-model="file.directus_files_id.title"/>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <md-button class="md-danger md-just-icon md-round md-sm"
                                                                           @click.stop="file.status = 'archived'">
                                                                    <md-icon>clear</md-icon>
                                                                </md-button>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </template>
                                                </tbody>
                                            </table>
                                        </div>

                                        <md-field>
                                            <label>Dateien hochladen</label>
                                            <md-file v-model="fileUpload"/>
                                        </md-field>
                                    </div>
                                </div>
                            </template>
                        </tabs>
                    </div>
                </div>
            </template>

            <template slot="footer">
                <div class="display--flex-between">
                    <md-button class="md-info md-simple"
                               @click.stop="showCourseDayDayItemModal=false;getCourseItemContent();">
                        Abbrechen
                    </md-button>
                    <md-button class="md-danger" @click.stop="deleteCourseDayDayItem">Löschen</md-button>
                    <md-button class="md-primary" @click.stop="updateCourseDayDayItem">Speichern</md-button>
                </div>
            </template>
        </modal>

        <modal v-if="showFilesModal" class="modal--overlay modal--media"
               :class="'modal--' + getFileType(currentFile.type)" @click.stop>
            <template slot="header">
                <md-button class="md-simple md-just-icon md-round modal__download-file"
                           @click.stop="downloadFile(currentFile)">
                    <md-icon>download</md-icon>
                </md-button>
                <md-button class="md-simple md-just-icon md-round modal-default-button"
                           @click.stop="currentFile=null;showFilesModal=false;">
                    <md-icon>clear</md-icon>
                </md-button>
            </template>
            <template slot="body">
                <template v-if="getFileType(currentFile.type) === 'image'">
                    <img :src="currentFile.filePath" :title="currentFile.filename_download"/>
                </template>
                <template v-else-if="getFileType(currentFile.type) === 'application'">
                    <vue-pdf-app :pdf="currentFile.filePath"/>
                </template>
                <template v-else-if="getFileType(currentFile.type) === 'audio'">
                    <audio controls="controls" :src="currentFile.filePath" />
                </template>
                <template v-else-if="getFileType(currentFile.type) === 'video'">
                    <video width="640" height="400" controls="controls" :src="currentFile.filePath" />
                </template>
            </template>
        </modal>

    </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */

import Modal from '@/components/custom/Modal.vue';
import Tabs from "@/components/custom/Tabs.vue";
import {factory} from "@/services/factory";
import {userService} from "@/services/user.service";
import VuePdfApp from "vue-pdf-app";
import axios from "axios";
import fi from "element-ui/src/locale/lang/fi";
import th from "element-ui/src/locale/lang/th";

export default {
    name: "CourseDayDayItem",
    components: {Modal, Tabs, VuePdfApp},
    props: {
        courseDayDayItem: {type: Object, default: null},
        isCourseOwner: {type: Boolean, default: false}
    },
    data() {
        return {
            showCourseDayDayItemModal: false,
            showFilesModal: false,
            fileUpload: null,
        }
    },

    mounted() {
        if (this.courseDayDayItem && this.courseDayDayItem.newItem && this.courseDayDayItem.newItem === true) {
            this.showCourseDayDayItemModal = true;
            this.courseDayDayItem.newItem = false;
        }
    },
    methods: {
        getCourseItemContent() {
            this.$parent.getCourseItemContent();
        },
        getFileType(filetype, type) {
            if (type === 'extension') {
                let extension = filetype.split('/')[1];
                if (extension.includes('officedocument')) {
                    if (extension.includes('spreadsheet')) {
                        return 'excel';
                    } else if (extension.includes('word')) {
                        return 'word';
                    } else if (extension.includes('presentation')) {
                        return 'powerpoint';
                    } else {
                        return 'blank';
                    }
                }
                return extension;
            }
            return filetype.split('/')[0];
        },
        deleteCourseDayDayItem() {
            if (this.courseDayDayItem.id) {
                this.directus.items('courseitemcontent').updateOne(this.courseDayDayItem.id, {
                    status: 'archived'
                }).then(() => {
                    this.getCourseItemContent();
                    this.showCourseDayDayItemModal = false;
                })
            }
        },
        updateCourseDayDayItem() {
            let promises = [];
            promises.push(this.directus.items('courseitemcontent').updateOne(this.courseDayDayItem.id, {
                title: this.courseDayDayItem.title,
                sort: this.courseDayDayItem.sort,
                description: this.courseDayDayItem.description
            }));

            // Add File
            if (this.fileUpload) {
                const fileInput = document.querySelector('input[type="file"]');
                promises.push(factory.fileUpload(fileInput.files[0]).then(response => {
                    return this.directus.items('courseitemcontent_files').createOne({
                        status: 'published',
                        courseitemcontent_id: this.courseDayDayItem.id,
                        directus_files_id: response.id
                    });
                }))
            }


            // Edit existing files
            this.courseDayDayItem.files.forEach(file => {
                    if (file.directus_files_id) {
                        // remove file
                        if (file.status === 'archived') {
                            promises.push(this.directus.items('courseitemcontent_files').updateOne(file.id, {
                                status: file.status
                            }));
                        }

                        if (typeof file.sort === 'string' || typeof file.sort === 'number') {
                            //update sort
                            promises.push(this.directus.items('courseitemcontent_files').updateOne(file.id, {
                                sort: file.sort
                            }));
                        }

                        //update title
                        promises.push(this.directus.items('directus_files').updateOne(file.directus_files_id.id, {
                            title: file.directus_files_id.title
                        }));
                    }
                }
            )

            Promise.all(promises).then(() => {
                this.fileUpload = null;
                this.showCourseDayDayItemModal = false;
                this.getCourseItemContent();
            })
        },
        handleFileClick(event, file) {
            event.preventDefault();
            const openFileTypes = ['image', 'video', 'audio'];
            if (openFileTypes.includes(this.getFileType(file.type)) || file.type === 'application/pdf') {
                //open and show file
                userService.refreshAuth().then(response => {
                    this.currentFile = file;
                    this.currentFile.filePath = this.$filePaths.before + file.filename_disk + this.$filePaths.after + response;
                    this.showFilesModal = true
                });
            } else {
                // download file
                this.downloadFile(file)
            }
        },
        downloadFile(file) {
            userService.refreshAuth().then(token => {
                axios({
                    url: this.$filePaths.before + file.filename_disk + this.$filePaths.after + token,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', file.filename_download);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    fileLink.remove();
                });
            });
        },
        startDrag(e, item) {
            if (!this.isCourseOwner) {
                return;
            }
            var params = {};
            if (item.courseitem) {
                params = {itemId: item.courseitem};
            }
            factory.startDrag(e, item.id, params);
        },
        handleDrag(e) {
            factory.handleDrag(e);
        },
        onDrop(e) {
            e.preventDefault();
            e.stopPropagation();
            if (!e.dataTransfer.getData('parentId')) {
                return;
            }

            let promises = factory.onDrop(e, 'courseitemcontent_files');

            Promise.all(promises).then(responses => {
                this.getCourseItemContent();
            });
        },
        onFileDrop(e) {
            //file Drop
            if (e.dataTransfer.files.length > 0) {
                e.preventDefault();
                e.stopPropagation();


                var promises = [];
                e.dataTransfer.files.forEach(file => {
                    promises.push(factory.fileUpload(file));
                });

                Promise.all(promises).then(responses => {
                    var promises2 = [];
                    responses.forEach(fileObj => {
                        promises2.push(this.directus.items('courseitemcontent_files').createOne({
                            status: 'published',
                            courseitemcontent_id: this.courseDayDayItem.id,
                            directus_files_id: fileObj.id
                        }));
                        Promise.all(promises2).then(() => {
                            this.getCourseItemContent();
                        }).catch(error => {
                            this.$parent.$parent.$parent.alertManager('Beim Upload der Datei ist ein Fehler aufgetreten', 'error-message', false, 5000, {
                                type: 'fileUploadError',
                                message: error
                            })
                        });
                    });
                }).catch(error => {
                    this.$parent.$parent.$parent.alertManager('Beim Upload der Datei ist ein Fehler aufgetreten', 'error-message', false, 5000, {
                        type: 'fileUploadError',
                        message: error
                    })
                });
            }
        },
    }
};
</script>