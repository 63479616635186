<template>
  <div class="wrapper">
    <parallax
        class="section page-header header-filter"
        parallax-active="true"
        :style="headerStyle"
    />
    <div class="main main-raised main--grey">
      <div class="section users-content">
        <div class="container-fluid">
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <tabs ref="UsersUsersTabElement" id="UsersUsersTabElement"
                    :tab-name="tabName" :tab-counter="tabCounter">
                <template slot="tab-pane-1" data-parent="UsersUsersTabElement" data-title="Meine">
                  <div class="md-layout md-layout--user-card">
                    <template v-for="connection in connections">
                      <div class="md-layout-item md-size-25 md-small-size-100" v-bind:key="connection.id"
                           v-if="connection.user">
                        <user-card v-if="connection.user" :user="connection.user"/>
                      </div>
                    </template>
                  </div>
                </template>
                <template slot="tab-pane-2" data-parent="UsersUsersTabElement" data-title="Anfragen">
                  <div class="md-layout md-layout--user-card">
                    <div class="md-layout-item md-size-100 pb-2"><h3>Bereits von mir Angefragt</h3></div>
                    <div class="md-layout-item md-size-25 md-small-size-100" v-for="request in openRequestsFromMe"
                         v-bind:key="request.id">
                      <user-card :user="request.user_foreign"/>
                    </div>
                    <div class="md-layout-item md-size-100 pb-2"><h3>Anfragen erhalten</h3></div>
                    <div class="md-layout-item md-size-25 md-small-size-100 users__item"
                         v-for="request in openRequestsToMe"
                         v-bind:key="request.id">
                      <md-button class="md-primary md-just-icon md-round users__cta users__cta--accept"
                                 @click="updateConnection(request.id, 'published')" title="Anfrage bestätigen">
                        <md-icon>check</md-icon>
                      </md-button>
                      <user-card :user="request.user_local"/>

                      <md-button class="md-danger md-just-icon md-round users__cta users__cta--decline"
                                 @click="updateConnection(request.id, 'archived')" title="Anfrage ablehnen">
                        <md-icon>clear</md-icon>
                      </md-button>
                    </div>
                  </div>
                </template>
                <template slot="tab-pane-3" data-parent="UsersUsersTabElement" data-title="Suche">
                  <div class="md-layout mb-2">
                    <div class="md-layout-item md-size-33 md-small-size-100">
                      <md-field>
                        <label>Suche</label>
                        <md-input v-model="searchParam_users" @keydown="searchUsers"></md-input>
                      </md-field>
                    </div>
                  </div>

                  <div class="md-layout md-layout--user-card">
                    <div class="md-layout-item md-size-25 md-small-size-100" v-for="user in users"
                         v-bind:key="user.id">
                      <user-card :user="user"/>
                    </div>
                  </div>

                  <div class="display--flex-center" v-if="users_meta && users_meta.page < users_meta.pages">
                    <md-button class="md-primary md-round" @click.stop="getUsers(users_meta.page + 1, 'append')">
                      Weitere Benutzer laden
                    </md-button>
                  </div>
                </template>
                <template slot="tab-pane-4" data-parent="UsersUsersTabElement" data-title="Suche">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-50 md-small-size-100">
                      <p>Um einen Kontakt einzuladen der Plattform beizutreten kannst du hier seine E-Mail eintragen
                        und </p>
                      <div class="md-layout">
                        <div class="md-layout-item md-size-50 md-small-size-100">
                          <form @submit="sendInvite">
                            <md-field>
                              <label>E-Mail</label>
                              <md-input v-model="inviteEmail" type="email" reqired/>
                            </md-field>
                            <md-button class="md-primary md-round" type="submit">Einladung senden</md-button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {factory} from "@/services/factory";
import Mixins from "@/plugins/basicMixins";
import UserCard from "/src/components/custom/UserCard.vue";
import Tabs from "@/components/custom/Tabs.vue";
import {userService} from "@/services/user.service";

export default {
  components: {
    UserCard,
    Tabs,
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "page--content",
  props: {
    user: Object,
    image: {
      type: String,
      default: require("@/assets/img/bg12.jpg")
    },
  },
  data() {
    return {
      users: [],
      users_meta: null,
      users_count: 12,
      connections: [],
      openRequestsFromMe: [],
      openRequestsToMe: [],
      newGroup: {},
      groups: [],
      editGroup: {},
      myGroups: [],
      contacts: [],
      courses: [],
      showEditGroupModal: false,
      inviteEmail: null,
      tabName: ['Meine', 'Anfragen', 'Suche', 'Einladen'],
      tabCounter: ['', '', '', ''],
      searchParam_users: '',
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getUsers();
      this.getConnections();
      this.getContacts();
      this.setOpenUserRequests();
    },
    setOpenUserRequests() {
      let requestCount = factory.getOpenUserRequests()
      if (requestCount > 0) {
        this.tabCounter[1] = requestCount;
      } else {
        this.tabCounter[1] = '';
      }
    },
    getUsers(page = 1, action = 'insert') {
      var queryParams = {
        filter: {
          id: {
            _neq: this.$user.user.id
          },
          status: 'active'
        },
        limit: this.users_count,
        page: page,
        meta: '*'
      }
      if (this.searchParam_users) {
        queryParams.search = this.searchParam_users
      }
      this.directus.items('directus_users').readByQuery(queryParams).then(response => {
        if (action === 'append') {
          this.users.push(...response.data);
        } else {
          this.users = response.data
        }
        this.users_meta = response.meta
        this.users_meta.page = page;
        this.users_meta.pages = Math.ceil(response.meta.filter_count / this.users_count);
      });
    },
    searchUsers() {
      if (this.searchtimeout) {
        clearTimeout(this.searchtimeout);
      }
      this.searchtimeout = setTimeout(this.getUsers, 500);
    },
    getConnections() {
      this.directus.users.me.read({fields: ['id']}).then(meData => {
        // --- only confirmed connection
        this.directus.items('user_connections').readByQuery({
          limit: -1,
          fields: [
            '*',
            'user_foreign.*',
            'user_local.*',
          ],
          filter: {
            '_or': [
              {user_local: meData.id},
              {user_foreign: meData.id}
            ],
            status: 'published'
          }
        }).then(connections => {
          this.connections = connections.data;
          this.connections.forEach(connection => {
            if (connection.user_local && connection.user_foreign) {
              if (connection.user_local.id !== meData.id) {
                connection.user = connection.user_local;
              } else {
                connection.user = connection.user_foreign;
              }
            }
          });
        });

        // --- open user requests ---
        this.directus.items('user_connections').readByQuery({
          limit: -1,
          fields: [
            '*',
            'user_foreign.*',
          ],
          filter: {
            user_local: meData.id,
            status: 'draft'
          }
        }).then(connections => {
          this.openRequestsFromMe = connections.data;
        });

        // --- open request from other useres to me ---
        this.directus.items('user_connections').readByQuery({
          limit: -1,
          fields: [
            '*',
            'user_local.*',
          ],
          filter: {
            user_foreign: meData.id,
            status: 'draft'
          }
        }).then(connections => {
          this.openRequestsToMe = connections.data;
          factory.setOpenUserRequests(connections.data.length)
        });
      });
    },
    getContacts() {
      factory.getContacts().then(response => {
        this.contacts = response
      })
    },
    updateConnection(requestId, newState) {
      var confirmResult;
      if (newState == 'archived') {
        confirmResult = confirm("Sicher dass die Anfrage entfernt werden soll?");
      }

      if (confirmResult !== false) {
        this.directus.items('user_connections').updateOne(requestId, {
          status: newState
        }).then(() => {
          factory.checkOpenUserRequests().then(() => {
            this.setOpenUserRequests();
            this.$parent.$refs.header.setOpenUserRequests();
          })
          this.init();
        })
      }
    },
    sendInvite(e) {
      e.preventDefault();
      if (!this.inviteEmail || !this.inviteEmail.length > 0) {
        this.$parent.alertManager('Bitte geben Sie eine korrekte E-Mail Adresse ein');
        return;
      }
      userService.inviteUser(this.inviteEmail).then(() => {
        this.$parent.alertManager('Vielen Dank! Die Einladung wurde verschickt.')
        this.inviteEmail = null;
      }).catch(error => {
        if (error.message === 'Field "email" has to be unique.') {
          this.$parent.alertManager('Dieser Kontakt wurde bereits eingeladen.', 'error-message')
        } else {
          this.$parent.alertManager('Beim Absenden der Einladung ist leider ein Fehler aufgetreten', 'error-message')
          console.log(error)
        }
      })
    }
  }
};
</script>