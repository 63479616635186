<template>
    <div class="md-table table--sort">
        <table>
            <thead>
            <tr>
                <th></th>
                <th>Titel</th>
                <th>
                    <md-button class="md-primary md-just-icon md-round md-sm" @click.stop="addStorage()">
                        <md-icon>add</md-icon>
                    </md-button>
                </th>
            </tr>
            </thead>
            <tbody data-draganddrop @drop="onDrop($event)" @dragover.prevent @dragenter.prevent>
            <tr :id="item.id" v-for="item in items" :key="item.id" class="md-table-row" :data-sort="item.sort" data-draganddrop-item>
                <td style="width: 50px;">
                    <span :draggable="true" @drag="handleDrag($event, item)" @dragstart="startDrag($event, item)">
                          <md-icon>drag_indicator</md-icon>
                    </span>
                </td>
                <td>
                    <md-field class="formfield--hidden formfield--no-space">
                        <md-input v-model="item.title" placeholder="Titel" @keyup="updateStorage(item)"/>
                    </md-field>
                </td>
                <td style="width: 50px;">
                    <md-button class="md-danger md-just-icon md-round md-sm" @click.stop="removeStorage(item)">
                        <md-icon>clear</md-icon>
                    </md-button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

</template>

<script>


import {factory} from "@/services/factory";

/**
 * @name CourseStorageTable
 */
export default {
    props: {
        items: {type: Array, default: null},
        course: {type: Object, default: null},
    },
    data() {
        return {
            updateStorageTimer: null
        }
    },
    methods: {
        getCourseItems() {
            this.$parent.$parent.$parent.$parent.$parent.$parent.getCourseItems()
        },
        removeStorage(item) {
            this.directus.items('courseitem').updateOne(item.id, {
                status: 'archived'
            }).then(() => {
                this.getCourseItems();
            })
        },
        addStorage() {
            let sort = 1;
            if (this.items[this.items.length - 1] && this.items[this.items.length - 1].sort) {
                sort = this.items[this.items.length - 1].sort + 1;
            }

            this.directus.items('courseitem').createOne({
                course: this.course.id,
                type: 'storage',
                sort: sort
            }).then(() => {
                this.getCourseItems();
            })
        },
        updateStorage(item) {
            if (this.updateStorageTimer) {
                clearTimeout(this.updateStorageTimer);
            }
            this.updateStorageTimer = setTimeout(() => {
                this.directus.items('courseitem').updateOne(item.id, {
                    title: item.title
                }).then(() => {
                    this.getCourseItems();
                }).catch(error => {
                    console.log(error);
                })
            }, 800);
        },
        startDrag(e, item) {
            factory.startDrag(e, this.course.id, {itemId: item.id});
        },
        handleDrag(e) {
            factory.handleDrag(e);
        },
        onDrop(e) {
            e.preventDefault();
            e.stopPropagation();
            if (!e.dataTransfer.getData('parentId')) {
                return;
            }
            let promises = factory.onDrop(e, 'courseitem');
            Promise.all(promises).then(() => {
                this.getCourseItems();
            });
        },
    },
}
</script>
