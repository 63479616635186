<template>
  <div class="wrapper">
    <div id="pre-footer-areas">
      <!-- Simple Social Line -->
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="social-line social-line-white text-center">
            <div class="container">
              <div class="md-layout">
                <div class="md-layout-item">
                  <h4 class="title">
                    Thank you for your support!
                  </h4>
                  <md-button
                    href="javascript:void(0)"
                    class="md-twitter md-round"
                  >
                    <i class="fab fa-twitter" /> Twitter · 2.5k
                  </md-button>
                  <md-button
                    href="javascript:void(0)"
                    class="md-facebook md-round"
                  >
                    <i class="fab fa-facebook-f" /> Facebook ·
                    3.2k
                  </md-button>
                  <md-button
                    href="javascript:void(0)"
                    class="md-google md-round"
                  >
                    <i class="fab fa-google-plus-g" /> Google ·
                    1.2k
                  </md-button>
                  <md-button
                    href="javascript:void(0)"
                    class="md-dribbble md-round"
                  >
                    <i class="fab fa-dribbble" /> Dribbble · 1.8k
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end Simple Social Line -->

      <br>
      <br>

      <!-- Simple Social Line -->
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="social-line social-line-big-icons social-line-white">
            <div class="container">
              <div class="md-layout">
                <div class="md-layout-item md-xsmall-size-50">
                  <md-button
                    href="javascript:void(0)"
                    class="md-twitter md-simple md-icon"
                  >
                    <i class="fab fa-twitter" />
                  </md-button>
                </div>
                <div class="md-layout-item md-xsmall-size-50">
                  <md-button
                    href="javascript:void(0)"
                    class="md-facebook md-simple md-icon"
                  >
                    <i class="fab fa-facebook" />
                  </md-button>
                </div>
                <div class="md-layout-item md-xsmall-size-50">
                  <md-button
                    href="javascript:void(0)"
                    class="md-google md-simple md-icon"
                  >
                    <i class="fab fa-google-plus-g" />
                  </md-button>
                </div>
                <div class="md-layout-item md-xsmall-size-50">
                  <md-button
                    href="javascript:void(0)"
                    class="md-dribbble md-simple md-icon"
                  >
                    <i class="fab fa-dribbble" />
                  </md-button>
                </div>
                <div class="md-layout-item md-xsmall-size-50">
                  <md-button
                    href="javascript:void(0)"
                    class="md-youtube md-simple md-icon"
                  >
                    <i class="fab fa-youtube" />
                  </md-button>
                </div>
                <div class="md-layout-item md-xsmall-size-50">
                  <md-button
                    href="javascript:void(0)"
                    class="md-instagram md-simple md-icon"
                  >
                    <i class="fab fa-instagram" />
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end Simple Social Line -->

      <br>
      <br>

      <!-- Simple Black Social Line -->
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="social-line social-line-big-icons social-line-black">
            <div class="container">
              <div class="md-layout">
                <div class="md-layout-item md-xsmall-size-50">
                  <md-button
                    href="javascript:void(0)"
                    class="md-twitter md-white md-simple md-icon"
                  >
                    <i class="fab fa-twitter" />
                  </md-button>
                </div>
                <div class="md-layout-item md-xsmall-size-50">
                  <md-button
                    href="javascript:void(0)"
                    class="md-facebook md-white md-simple md-icon"
                  >
                    <i class="fab fa-facebook" />
                  </md-button>
                </div>
                <div class="md-layout-item md-xsmall-size-50">
                  <md-button
                    href="javascript:void(0)"
                    class="md-google md-white md-simple md-icon"
                  >
                    <i class="fab fa-google-plus-g" />
                  </md-button>
                </div>
                <div class="md-layout-item md-xsmall-size-50">
                  <md-button
                    href="javascript:void(0)"
                    class="md-dribbble md-white md-simple md-icon"
                  >
                    <i class="fab fa-dribbble" />
                  </md-button>
                </div>
                <div class="md-layout-item md-xsmall-size-50">
                  <md-button
                    href="javascript:void(0)"
                    class="md-youtube md-white md-simple md-icon"
                  >
                    <i class="fab fa-youtube" />
                  </md-button>
                </div>
                <div class="md-layout-item md-xsmall-size-50">
                  <md-button
                    href="javascript:void(0)"
                    class="md-instagram md-white md-simple md-icon"
                  >
                    <i class="fab fa-instagram" />
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end Simple Black Social Line -->

      <br>
      <br>

      <!-- Image Subscribe Line -->
      <div class="md-layout">
        <div class="md-layout-item">
          <div
            class="subscribe-line subscribe-line-image"
            :style="imageSubscribe"
          >
            <div class="container">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-50 md-small-size-70 md-xsmall-size-100 mx-auto"
                >
                  <div class="text-center">
                    <h3 class="title">
                      Subscribe to our Newsletter
                    </h3>
                    <p class="description">
                      Join our newsletter and get news in your inbox every week!
                      We hate spam too, so no worries about this.
                    </p>
                  </div>
                  <md-card class="md-card-form-horizontal">
                    <md-card-content>
                      <form
                        action=""
                        method=""
                      >
                        <div class="md-layout">
                          <div
                            class="md-layout-item md-large-size-66 medium-size-50"
                          >
                            <md-field class="md-form-group">
                              <md-icon>mail</md-icon>
                              <md-input
                                v-model="subscribe"
                                placeholder="Subscribe"
                              />
                            </md-field>
                          </div>
                          <div
                            class="md-layout-item md-large-size-33 medium-size-50"
                          >
                            <md-button
                              class="md-primary md-block"
                            >
                              Subscribe
                            </md-button>
                          </div>
                        </div>
                      </form>
                    </md-card-content>
                  </md-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end Image Subscribe Line -->

      <br>
      <br>

      <!-- Simple Subscribe Line -->
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="subscribe-line subscribe-line-white">
            <div class="container">
              <div class="md-layout">
                <div class="md-layout-item md-medium-size-50 md-small-size-100">
                  <h3 class="title">
                    Get Tips &amp; Tricks every Week!
                  </h3>
                  <p class="description">
                    Join our newsletter and get news in your inbox every week!
                    We hate spam too, so no worries about this.
                  </p>
                </div>
                <div
                  class="md-layout-item md-medium-size-50 md-small-size-100 d-flex-center"
                >
                  <md-card class="md-card-form-horizontal md-card-plain">
                    <md-card-content>
                      <form
                        action=""
                        method=""
                      >
                        <div class="md-layout">
                          <div
                            class="md-layout-item md-large-size-66 medium-size-50"
                          >
                            <md-field class="md-form-group">
                              <md-icon>mail</md-icon>
                              <md-input
                                v-model="subscribe"
                                placeholder="Subscribe"
                              />
                            </md-field>
                          </div>
                          <div
                            class="md-layout-item md-large-size-33 medium-size-50"
                          >
                            <md-button
                              class="md-rose md-round md-block"
                            >
                              Subscribe
                            </md-button>
                          </div>
                        </div>
                      </form>
                    </md-card-content>
                  </md-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end Simple Subscribe Line -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      subscribe: null,
      image: require("@/assets/img/bg7.jpg")
    };
  },
  computed: {
    imageSubscribe() {
      return {
        backgroundImage: `url(${this.image})`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.d-flex-center {
  display: flex;
  align-items: center;
}
</style>
