<template>
  <div class="wrapper">
    <notifications ref="notifications"/>
    <div class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="login__wrapper">
            <form @submit="mailRegister">
              <login-card header-color="green">
                <md-button slot="buttons" class="md-simple md-white" disabled>
                  Registrieren
                </md-button>


                <!--
                <md-button slot="buttons" href="javascript:void(0)" class="md-just-icon md-simple md-white">
                  <i class="fab fa-facebook-square"/>
                </md-button>

                <md-button slot="buttons" href="javascript:void(0)" class="md-just-icon md-simple md-white">
                  <i class="fab fa-google"/>
                </md-button>
                -->

                <md-field slot="inputs" class="md-form-group" id="email">
                  <md-icon>email</md-icon>
                  <label>E-Mail</label>
                  <md-input v-model="user.email" type="email" required/>
                  <span class="md-error">Bitte eine korrekte E-Mail Addresse eingeben</span>
                </md-field>

                <md-button type="submit" slot="footer" class="md-simple md-success md-lg">Registrieren</md-button>
              </login-card>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {LoginCard} from "@/components";
import {Notifications} from "@/components";
import Mixins from "@/plugins/basicMixins";
import {userService} from "@/services/user.service";

export default {
  components: {
    LoginCard,
    Notifications
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "login-page",
  data() {
    return {
      image: require("@/assets/img/profile_city.jpg"),
      firstname: null,
      fields: {},
      errorClass: 'md-invalid',
      hasError: false,
      user: {
        email: null,
      },
      notifications: [],
      mailRegex: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
    };
  },
  mounted: function () {
    this.fields['email'] = document.getElementById('email')
  },
  methods: {
    mailRegister: function (e) {
      e.preventDefault();
      this.hasError = false;


      if (!this.mailRegex.test(this.user.email)) {
        this.handleFieldError(this.fields['email'], 'add');
        this.hasError = true;
      }

      if (this.hasError === false) {
        userService.inviteUser(this.user.email).then(() => {
          this.$parent.alertManager('Vielen Dank für die Registriereung! Dir wurde eine Email mit weiteren Anweisungen zugeschickt.' ,'success-message')
          this.inviteEmail = null;
        }).catch(error => {
          if(error.message === 'Field "email" has to be unique.') {
            this.$parent.alertManager('Diese E-Mail Adresse wurde bereits verwendet.', 'error-message', false, 5000, {type: 'registerError', message: JSON.stringify(error)})
          } else {
            this.$parent.alertManager('Beim Absenden der Einladung ist leider ein Fehler aufgetreten. Bitte versuche es erneut', 'error-message', false, 5000, {type: 'registerError', message: error})
          }
        })
        /*
        userService.register(this.user).then(response => {
          if (response.success === true) {
            this.$router.push('/')
          } else {
            this.$refs.notifications.addNotification('loginError', 'danger', 'Registrierung Fehlgeschlagen', 'Ihre Registrierung ist fehlgeschlagen, bitte versuchen Sie es erneut.')
          }
        })

         */
      }
    },
    handleFieldError: function (field, action = 'remove') {
      if (action === 'remove') {
        field.classList.remove(this.errorClass)
      } else if (action === 'add') {
        field.classList.add(this.errorClass)
      }
    }
  }
};
</script>

<style lang="css"></style>
