<template>
  <div class="select" :class="{'select--open' : dropdownOpen}">
    <div class="select__background" @click="dropdownOpen=false"></div>
    <div class="select__inner" @click="dropdownOpen=true">
      <md-field class="select__display" :class="{'md-focused' : dropdownOpen || selectedOptions.length > 0}">
        <label v-if="title">{{ title }}</label>
        <div class="select__list--selected">
          <div class="select__item-selected" v-for="option in selectedOptions" v-bind:key="option.id"
               @click.stop="toggleItem(option, 'remove')">
            <span v-for="label in labels" v-bind:key="label.index">
                {{ option[label] }}
            </span>
            <md-icon class="select__item-close">clear</md-icon>
          </div>
        </div>
      </md-field>
      <div class="select__dropdown">
        <div class="select__dropdown-inner">
          <div class="select__dropdown-close" @click.stop="dropdownOpen=false">
            <md-icon>clear</md-icon>
          </div>
          <div class="select__list">
            <div class="select__item" v-for="option in options" v-bind:key="option.id" @click.stop="toggleItem(option)"
                 :class="{'select__item--active' : isActive(option)}">

            <span v-for="label in labels" v-bind:key="label.index">
                {{ option[label] }}
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

/**
 * @name Select
 */
export default {
  props: {
    value: {type: Array, default: () => {return []}},
    options: Array,
    title: String,
    labels: {type: Array, default: () => {
      return ['title']
      }}
  },
  data() {
    return {
      selectedOptions: this.value,
      dropdownOpen: false
    }
  },
  computed: {},
  watch: {},
  methods: {
    toggleItem(option, action = '') {
      let index = this.selectedOptions.findIndex(selectedOption => selectedOption.id === option.id);

      if (action === 'remove') {
        if (index >= 0) {
          this.selectedOptions.splice(index, 1);
        }
        return true;
      }

      if (index >= 0) {
        this.selectedOptions.splice(index, 1);
      } else {
        this.selectedOptions.push(option)
      }
      this.$emit('input', this.selectedOptions);
    },
    isActive(option) {
      let index = this.selectedOptions.findIndex(selectedOption => selectedOption.id === option.id);
      return index >= 0;
    }
  },
}
</script>
