<template>
    <div class="wrapper">
        <parallax
                class="section page-header header-filter"
                parallax-active="true"
                :style="headerStyle"
        />
        <div class="main main-raised">
            <div class="section users-content">
                <div class="container">
                    <div class="display--flex-between display--flex-wrap">
                        <h2>Kurse</h2>
                        <div>
                            <md-field>
                                <label>Suche</label>
                                <md-input v-model="searchparam" @keydown="searchCourse"></md-input>
                            </md-field>
                        </div>
                        <div class="button-group py-2" v-if="filterItems">
                            <md-button :class="[filterItem.active ? 'md-primary' : '']"
                                       v-for="(filterItem, index) in filterItems"
                                       v-bind:key="filterItem.id" @click.stop="handleFilterItem($event, index)">
                                {{ filterItem.title }}
                            </md-button>
                        </div>
                        <md-button class="md-primary md-just-icon md-round" @click="showModal = true">
                            <md-icon>add</md-icon>
                        </md-button>
                    </div>
                    <div class="md-layout draganddrop" v-if="courses" @drop="onDrop($event)" @dragover.prevent
                         @dragenter.prevent data-draganddrop>
                        <div class="md-layout-item md-size-33 md-small-size-50 course__drag" v-for="course in courses"
                             v-bind:key="course.id" :id="course.id" :data-sort="0" data-draganddrop-item>
                            <course-card :course="course"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal v-if="showModal" class="modal--overlay">
            <template slot="header">
                <h4 class="modal-title">Kurs hinzufügen</h4>
                <md-button class="md-simple md-just-icon md-round modal-default-button" @click="showModal = false">
                    <md-icon>clear</md-icon>
                </md-button>
            </template>

            <template slot="body">
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-field>
                            <label>Titel</label>
                            <md-input v-model="newCourse.title"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-field>
                            <label>Beschreibung</label>
                            <md-textarea v-model="newCourse.description"></md-textarea>
                        </md-field>
                    </div>
                </div>

                <div class="alert alert-danger" v-if="newCourseError">
                    <div class="container">
                        <span>Bitte einen Titel eingeben!</span>
                    </div>
                </div>
            </template>

            <template slot="footer">
                <div class="display--flex-between">
                    <md-button class="md-danger md-simple" @click="showModal = false">Abbrechen</md-button>
                    <md-button class="md-primary" @click="addCourse">Speichern</md-button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>

import {factory} from "../../services/factory";
import Mixins from "@/plugins/basicMixins";
import Modal from '@/components/custom/Modal.vue';
import CourseCard from '@/components/custom/CourseCard.vue';


export default {
    components: {Modal, CourseCard},
    mixins: [Mixins.HeaderImage],
    bodyClass: "page--content",
    props: {
        user: Object,
        image: {
            type: String,
            default: require("@/assets/img/bg9.jpg")
        },
    },
    data() {
        return {
            courses: null,
            showModal: false,
            newCourse: {},
            newCourseError: false,
            dragActiveClass: 'course__dropzone--active',
            searchparam: '',
            searchtimeout: null,
            filterItems: [
                {
                    id: "active",
                    title: 'Aktuelle Kurse',
                    active: true
                },
                {
                    id: "archived",
                    title: 'Archivierte Kurse',
                    active: true
                }
            ]
        };
    },
    mounted() {
        this.initFilter();
        this.getCourses();
    },
    methods: {
        getCourses() {
            var courseQueryParams = {
                fields: [
                    '*',
                    'owner.*',
                ],
                limit: -1
            }
            if (this.searchparam) {
                courseQueryParams.search = this.searchparam
            }

            courseQueryParams.filter = {
                _and: []
            };

            this.filterItems.forEach(item => {
                if (item.active === false) {
                    switch (item.id) {
                        case "archived":
                            courseQueryParams.filter['_and'].push(
                                {
                                    'status': {
                                        "_neq": "archived"
                                    }
                                }
                            );
                            break;
                        case "active":
                            courseQueryParams.filter['_and'].push(
                                {
                                    'status': {
                                        "_neq": "published"
                                    }
                                }
                            );
                            break;
                    }
                }
            })


            this.directus.items('course').readByQuery(courseQueryParams).then(response => {
                response.data = response.data.filter(course => course.parentcourseitem.length === 0);
                var promises = [];
                response.data.forEach(course => {
                    promises.push(this.directus.items('coursesort').readByQuery({
                        filter: {
                            "user_created": $user.user.id,
                            "course": course.id
                        },
                        limit: 1
                    }).then(coursesort => {
                        if (coursesort.data.length > 0) {
                            course['coursesort'] = coursesort.data[0].sort
                        } else {
                            course['coursesort'] = 0
                        }
                    }));
                })

                Promise.all(promises).then(() => {
                    response.data.sort((a, b) => a.coursesort > b.coursesort ? 1 : -1);
                    this.courses = response.data;
                });

            })
        },
        searchCourse() {
            if (this.searchtimeout) {
                clearTimeout(this.searchtimeout);
            }
            this.searchtimeout = setTimeout(this.getCourses, 500);
        },
        addCourse() {
            if (this.newCourse.title) {
                this.newCourseError = false;
                this.newCourse.owner = this.$user.user.id;
                this.directus.items('course').createOne(this.newCourse).then(response => {
                    this.showModal = false;
                    this.newCourse = {};
                    this.getCourses();
                });
            } else {
                this.newCourseError = true;
            }
        },
        onDrop(e) {
            factory.onDrop();
            let promises = [];
            let course_Id = e.dataTransfer.getData('parentId');

            if (!course_Id) {
                return;
            }

            this.directus.items('coursesort').readByQuery({
                filter: {
                    "user_created": $user.user.id
                }
            }).then(response => {
                var promises = [];
                e.target.closest('[data-draganddrop]').childNodes.forEach((sortItem, index) => {
                    if (sortItem.id) {
                        var coursesortItem = response.data.find(coursesort => coursesort.course === sortItem.id)
                        if (coursesortItem) {
                            promises.push(this.directus.items('coursesort').updateOne(coursesortItem.id, {
                                sort: index
                            }));
                        } else {
                            promises.push(this.directus.items('coursesort').createOne({
                                course: sortItem.id,
                                sort: index
                            }));
                        }
                    }
                });
            })
            Promise.all(promises).then(() => {
                //this.$parent.getCourseItems();
            });
        },
        initFilter() {
            let filterConfig = JSON.parse(localStorage.getItem('courseFilterItems'));
            if (filterConfig) {
                this.filterItems.forEach(item => {
                    item.active = filterConfig.find(configItem => {
                        return configItem.id === item.id
                    }).active
                })
            }
        },
        handleFilterItem(e, index) {
            let countActiveFitlers = 0;
            e.preventDefault();
            e.stopPropagation();
            this.filterItems[index].active = !this.filterItems[index].active
            this.filterItems.forEach(item => {
                if (item.active) {
                    countActiveFitlers++;
                }
            })
            if (countActiveFitlers === 0) {
                this.filterItems.forEach(item => {
                    item.active = true;
                })
            }
            this.getCourses();
            localStorage.setItem('courseFilterItems', JSON.stringify(this.filterItems));
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
