<template>
  <div class="iconlist__item-inner">
    <template v-if="item.type === 'image'">
      <img :src="item.avatarSrc" alt="Person Poster" v-if="item.avatarSrc">
      <span class="iconlist__item-icon" v-else>
        <md-icon>person</md-icon>
      </span>
    </template>
    <span class="iconlist__item-icon" v-else>
      <md-icon v-if="item.type === 'group'">groups</md-icon>
      <md-icon v-else-if="item.type === 'single'">person</md-icon>
      <md-icon v-else-if="item.type === 'course'">book</md-icon>
    </span>
    <div class="iconlist__item-content">
      <p class="text--normal-weight">
        {{ item.title }}
      </p>
      <p class="text--extra-small" v-if="item.text">
        {{ item.text | truncate(30) }}
      </p>
    </div>
    <div class="iconlist__item-right">

      <span class="iconlist__item-counter text--extra-small" v-if="item.counter > 0">
        {{ item.counter }}
      </span>
      <span class="iconlist__item-time text--extra-small" v-if="item.date_created">
        {{ item.date_created | formatToDate('chatDate') }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: "IconList",
  props: {
    item: Object,
    searchState: Object
  },
};
</script>