<template>
  <div class="wrapper">
    <parallax class="page-header header-filter" parallax-active="false" :style="headerStyle">
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper">
          </div>
        </div>
      </div>
      <div class="brand">
        <h1>Symmle</h1>
      </div>
    </parallax>
    <div id="main-panel" class="main main-raised">
      <!-- Features 5 -->
      <div class="section section-features-5">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
              <h2 class="title">
                Symmle verbindet die Welt der Daten mit der Welt der Herzen
              </h2>
              <h4 class="subtitle">

                Symmle ist das Netzwerk für Erwachsenenbildung in der Schweiz. <br /><br />

                Du möchtest unterrichten?<br /> Hier findest du Menschen aus deiner Region, die lernen wollen.<br /><br />

                Du möchtest Lernen?<br /> Hier findest Du die Lehrkräfte, die wirklich zu dir passen.<br /><br />
              </h4>
              <h5 class="subtitle">
                Symmle steht für <b>symm</b>ediales <b>Le</b>rnen, für ein Lernen, dass
                Kompetenzorientierung wirklich ernst nimmt,
                da es alle momentan erhältlichen medialen Möglichkeiten in sich vereint. Dabei stehen wir zur
                Alltagsrealiät des Internets und schätzen menschliche Nähe.
              </h5>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-33 md-small-size-100" v-for="feature in features"
                 v-bind:key="feature.id">
              <info-areas text-center :icon="feature.icon">
                <h4 slot="title" class="info-title">{{ feature.title }}</h4>
                <div slot="content" class="description">
                  <p>{{ feature.text }}</p>
                </div>
              </info-areas>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
              <p class="text--medium text--black text--with-list">
                Die Erwartungen an das Lernen sind kulturell und durch persönliche Erfahrungen geprägt. Vermutlich nirgends
                wird dies so deutlich, wie in Kursen mit erwachsenen Personen aus unterschiedlichen Kulturkreisen. Da sind
                jene, die in die Schweiz kommen, weil sie von ihrem Arbeitgeber geholt wurden, jene, die aus Kriegsgebieten
                flüchten musste, jene, die einfach die Chance beim Schopf gepackt haben, jene, die hoffen, dass ihr Leben
                etwas einfacher wird, jene, die hoffen, dass sie Freiheit gewinnen können, jene die studiert haben und jene,
                die noch nie eine Schule besuchen durften, jene, die sich zugehörig fühlen und jene, die sich abgelehnt
                fühlen, jene, die wissen, wer sie sind und jene die sich suchen. <br />
                Eine solche Liste kann endlos sein und wir lassen es zunächst einmal dabei, aber klar sollte nun geworden
                sein, dass beim Zweitspracherwerb eine Vielfalt aufeinandertrifft, die vielleicht nirgends sonst mehr auf
                solch engem Raum zusammenkommt. Das ist gelebte Diversität. Das ist eine Ansammlung an Kompetenz, die sich
                gewaschen hat. Die Chance voneinander zu lernen ist so gross, wie in kaum einem anderen Setting. Wir möchten
                sogar sagen, dass wir es jedem Einzelnen wünschen können, einmal im Leben an solch einem Setting teilhaben
                zu dürfen - egal mit welchem Hintergrund. Ob Du die Sprache des Landes lernen willst, oder ob Du sie bereits
                als Kind aufgesaugt hast, spielt dabei eigentlich gar keine Rolle, denn wir alle können uns in solch einer
                Umgebung entwickeln, wir alle können dabei grösser werden.<br />
                Symmle bietet genau dieses Setting mit selbst einstellbarem Fokusbereich.
                <br /><br />
                Noch stecken wir in den Kinderschuhen, aber wir entwickeln uns laufend weiter. Auf diesen Seiten werdet ihr
                vielfältige Möglichkeiten des Austauschs und der Weiterentwicklung finden, wie zum Beispiel:
              </p>
              <ul class="list list--clean text--black">
                <li>Koversationstreffen</li>
                <li>Interviews</li>
                <li>Kooperationen</li>
                <li>Events in der Nähe</li>
                <li>Angebote und Gesuche</li>
                <li>Wissensaustausch</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- end Features 5 -->
    </div>
  </div>
</template>

<script>

import Mixins from "@/plugins/basicMixins";
import {InfoAreas} from "@/components";

export default {
  name: "Home",
  components: {
    InfoAreas
  },
  mixins: [Mixins.VerticalNav, Mixins.HeaderImage],
  bodyClass: "page--home",
  props: {
    image: {
      type: String,
      default: require("@/assets/img/bg11.jpg")
    },
    featureImage: {
      type: String,
      default: require("@/assets/img/dg6.jpg")
    }
  },
  data() {
    return {
      firstname: null,
      email: null,
      password: null,
      leafShow: false,
      features: [
        {
          icon: 'home',
          title: 'Symmle ist regional',
          text: 'Wir sind im Netz und wir sind um die Ecke.'
        },
        {
          icon: 'sync_alt',
          title: 'Symmle ist individuell und menschlich fassbar',
          text: 'Wir haben es uns zur Aufgabe gemacht, die Vorteile des Onlinelernens mit den Vorteilen persönlicher Treffen zu vereinen.'
        },
        {
          icon: 'wifi_tethering',
          title: 'Symmle ist da, wo Du es benötigst',
          text: 'Egal, ob Du das Lernen Deiner Kindheit schätzt, oder ob Du mittlerweile die Chancen des Internets nutzen kannst, bei uns kannst Du beides vereinen.'
        },
        {
          icon: 'account_circle',
          title: 'Symmle ist Werbung für Dich',
          text: 'Hier kannst Du Dich präsentieren mit all Deiner Erfahrung, Deiner Empathie, Deinem Denken, Deinem Wissen, Deinen Wünschen oder auch Deinen Fehlern.'
        },
        {
          icon: 'fingerprint',
          title: 'Symmle ist sicher',
          text: 'Du kannst selbst entscheiden welche Informationen du auf der Plattform preis gibst. Deine Daten werden nicht weitergegeben.'
        },
        {
          icon: 'linear_scale',
          title: 'Symmle ist die einstellbare Blende von Nähe und Zurückgezogenheit.',
          text: ''
        }
      ]
    };
  },
  mounted() {
    this.leafActive();
    window.addEventListener("resize", this.leafActive);
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.leafActive);
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    leafActive() {
      if (window.innerWidth < 768) {
        this.leafShow = false;
      } else {
        this.leafShow = true;
      }
    }
  }
};
</script>
<style lang="scss">

</style>
