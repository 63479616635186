<template>
    <md-card
            class="md-card-tabs"
            :class="[
      { 'flex-column': flexColumn },
      { 'nav-pills-icons': navPillsIcons },
      { 'md-card-plain': plain }
    ]"
    >
        <md-card-header slot="header-title"/>

        <md-card-content>
            <md-list
                    class="nav-tabs"
                    :class="{
          'justify-content-center': pillsAlign === 'center',
          'justify-content-right': pillsAlign === 'right'
        }"
            >
                <md-list-item
                        v-for="(item, index) in tabName"
                        :key="item"
                        :class="[
            { active: isActivePanel(tabName[index]) },
            { [getColorButton(colorButton)]: isActivePanel(tabName[index]) }
          ]"
                        @click="switchPanel(tabName[index])"
                >
                    {{ tabName[index] }}
                    <template v-if="tabCounter">
            <span class="tab__nav-counter" v-if="tabCounter[index]">
              {{ tabCounter[index] }}
            </span>
                    </template>
                    <template v-if="tabIcon">
                        <md-icon v-if="tabIcon[index]">
                            {{ tabIcon[index] }}
                        </md-icon>
                    </template>

                </md-list-item>
            </md-list>

            <transition
                    name="fade"
                    mode="out-in"
            >
                <div class="tab-content">
                    <template v-for="(item, index) in tabName">
                        <template v-if="isActivePanel(tabName[index])">
                            <div
                                    :key="item"
                                    :class="getTabContent(index + 1)"
                            >
                                <slot :name="getTabContent(index + 1)"/>
                            </div>
                        </template>
                    </template>
                </div>
            </transition>
        </md-card-content>
    </md-card>
</template>

<script>
export default {
    name: "Tabs",
    props: {
        id: String,
        pillsAlign: String,
        flexColumn: Boolean,
        navPillsIcons: Boolean,
        plain: Boolean,
        tabName: Array,
        tabIcon: Array,
        tabCounter: Array,
        colorButton: {
            type: String,
            default: ""
        },
        onChange: Function
    },
    data() {
        return {
            activePanel: this.tabName[0]
        };
    },
    mounted() {
        this.initActiveTab()
    },
    methods: {
        switchPanel(panel) {
            if (this.activePanel !== panel && this.onChange !== undefined) {
                this.onChange();
            }
            this.activePanel = panel;
            this.setActiveTag(panel);
        },
        isActivePanel(panel) {
            return this.activePanel == panel;
        },
        getColorButton: function (colorButton) {
            return "md-" + colorButton + "";
        },
        getTabContent: function (index) {
            return "tab-pane-" + index + "";
        },
        initActiveTab() {
            if (this.id) {
                let initIndex = localStorage.getItem(this.id + '-activeIndex');
                if (initIndex && this.tabName[initIndex]) {
                    this.switchPanel(this.tabName[initIndex])
                }
            }
        },
        setActiveTag(panel) {
            localStorage.setItem(this.id + '-activeIndex', this.tabName.indexOf(panel));
        }
    }
};
</script>

<style lang="css" scoped>
.justify-content-right {
    justify-content: flex-end;
}
</style>
