<template>
  <div class="dropzone"
       v-on:drag="dropHandler($event)"
       v-on:dragstart="dropHandler($event)"
       v-on:dragend="dropHandler($event)"
       v-on:dragover="dropHandler($event)"
       v-on:dragenter="dropHandler($event)"
       v-on:dragleave="dropHandler($event)"
       v-on:drop="dropHandler($event)"
       @click="$refs.fileinput.click()"
  >
    <div class="dropzone__inner">
      <md-icon class="dropzone__icon" v-if="uploadComplete">file_download_done</md-icon>
      <md-icon class="dropzone__icon" v-else-if="uploadProgress">hourglass_top</md-icon>
      <md-icon class="dropzone__icon" v-else>upload</md-icon>
      <span class="dropzone__label">Hochladen</span>
    </div>
    <input class="dropzone__input" type="file" @change="fileHandler($event)" ref="fileinput"/>
  </div>
</template>

<script>

import {factory} from "@/services/factory";

export default {
  name: "Dropzone",
  props: {},
  data() {
    return {
      files: [],
      uploadProgress: false,
      uploadComplete: false,
    }
  },
  methods: {
    dropHandler(e) {
      this.uploadProgress = true;
      e.preventDefault()
      if (e.type === 'drop') {
        this.fileUpload(e.dataTransfer.files);
      }
    },
    fileHandler(e) {
      this.uploadProgress = true;
      this.fileUpload(e.target.files);
    },
    fileUpload(files) {
      factory.fileUpload(files[0])
          .then(response => {
            this.uploadProgress = false;
            this.uploadComplete = true;
            this.$parent.afterFileUpload(response);
            setTimeout(() => {
              this.uploadComplete = false;
            }, 2000)
          })
          .catch(error => {
            this.$parent.$parent.alertManager('Beim Upload der Datei ist ein Fehler aufgetreten', 'error-message', false, 5000, {type: 'fileUploadError', message: error})
          });
    }
  }
};
</script>
