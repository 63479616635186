<template>
  <div id="symmle" :class="{ 'nav-open': NavbarStore.showNavbar }">
    <div id="notifications">
      <div class="alert alert--modal alert--sticky" :class="alert.classes" v-if="alert.visible">
        <button type="button" aria-hidden="true" class="close" @click="closeAlert">
          <md-icon>clear</md-icon>
        </button>
        <div class="alert-icon">
          <md-icon>info_outline</md-icon>
        </div>
        <span v-html="alert.text"></span>
        <collapse v-if="alert.subtext" :collapse="['Details']" icon="keyboard_arrow_down">
          <template slot="md-collapse-pane-1">
            <p class="alert__subtext" v-html="alert.subtext"></p>
          </template>
        </collapse>
      </div>
    </div>

    <router-view name="header" v-bind:key="$route.fullPath" ref="header"/>
    <router-view/>
    <router-view name="footer"/>

    <Chat v-if="$user.user"/>

  </div>
</template>

<script>

import {factory} from "@/services/factory";
import {Collapse} from '@/components'
import Chat from '@/components/custom/Chat';

export default {
  name: 'RootApp',
  components: {Collapse, Chat},
  props: [],
  data() {
    return {
      alert: {
        visible: false,
        classes: '',
        text: '',
        subtext: '',
        icon: 'info_outline',
        error: {
          end: ' konnte leider nicht aktualisiert werden. Bitte versuchen Sie es noch einmal.'
        },
        success: {
          end: ' wurde erfolgreich aktualisiert.'
        },
        timeout: null
      },
    }
  },
  mounted() {
    this.checkOpenUserRequest();
  },
  methods: {
    alertManager(text, type = '', autoclose = true, timeout = 5000, errorObj) {
      clearTimeout(this.alert.timeout)
      switch (type) {
        case 'danger':
          this.alert.text = text + ' ' + this.alert.error.end;
          this.alert.classes = 'alert-' + type;
          break;
        case 'success':
          this.alert.text = text + ' ' + this.alert.success.end;
          this.alert.classes = 'alert-' + type;
          break;
        case 'error-message':
          this.alert.text = text;
          this.alert.classes = 'alert-danger';
          break;
        case 'success-message':
          this.alert.text = text;
          this.alert.classes = 'alert-success';
          break;
        default:
          this.alert.text = text
          this.alert.classes = 'alert-' + type;
          break;
      }

      this.alert.visible = true;

      if (errorObj) {
        this.directus.items('symmle_log').createOne({
          type: errorObj.type,
          message: {
            appVersion: window.navigator.appVersion,
            language: window.navigator.language,
            vendor: window.navigator.vendor,
            platform: window.navigator.platform,
            userAgentData: window.navigator.userAgentData,
            error: errorObj.message
          }
        });
        errorObj.message.response.parent.config.data = JSON.parse(errorObj.message.response.parent.config.data)
        errorObj.message.response.parent.config.data.password = "***"
        this.alert.subtext = errorObj;
      }

      if (autoclose) {
        this.alert.timeout = setTimeout(() => {
          this.closeAlert();
        }, timeout);
      }
    },
    closeAlert() {
      this.alert.visible = false;
      this.alert.text = '';
      this.alert.subtext = '';
    },
    checkOpenUserRequest() {
      if (parseInt(localStorage.getItem('openUserRequests_lastCheck')) < parseInt(new Date().getTime()) - 300000) {
        factory.checkOpenUserRequests();
      }
    }
  }
};
</script>