<template>
  <div class="wrapper">
    <parallax
        class="section page-header header-filter"
        parallax-active="true"
        :style="headerStyle"
    />
    <div class="main main-raised">
      <div class="section users-content">
        <div class="container">
          <div class="userdetail" v-if="profile">
            <div class="display--flex-between display--flex-wrap">
              <div>
                <p>{{ profile.title }}</p>
                <h1>{{ profile.first_name }} {{ profile.last_name }}</h1>
              </div>
              <div>
                <div v-if="profile.id !== $user.user.id && connected !== null">
                  <md-button class="md-primary md-round md-sm" @click="manageContact('approve')"
                             v-if="showApproveButton" title="Anfrage">
                    <md-icon>person_add</md-icon>
                    <span>Anfrage annehmen</span>
                  </md-button>
                  <md-button v-if="connected === false" class="md-primary md-round md-sm" @click="manageContact"
                             title="Anfragen">
                    <md-icon>person_add</md-icon>
                    <span>Anfragen</span>
                  </md-button>
                  <md-button v-else class="md-danger md-round md-sm" @click="manageContact" title="Entfernen">
                    <md-icon>person_remove</md-icon>
                    <span v-if="approved">Kontakt entfernen</span>
                    <span v-else>Anfrage entfernen</span>
                  </md-button>
                </div>
              </div>
            </div>

            <p class="text--large">{{ profile.description }}</p>
            <p>{{ profile.location }}</p>
            <p>Interessen: <span v-for="tag in profile.tags" v-bind:key="tag.id">{{ tag }}</span></p>
            <h2>Kurse</h2>
            <ul class="userdetail__courses">
              <li class="userdetail__course" v-for="course in courses" :key="course.id">
                {{ course.title }}
              </li>
            </ul>
            <h2>Kontakte</h2>
            <h2>Gruppen</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Mixins from "@/plugins/basicMixins";
import {factory} from "@/services/factory";

export default {
  components: {},
  mixins: [Mixins.HeaderImage],
  bodyClass: "page--content",
  props: {
    image: {
      type: String,
      default: require("@/assets/img/bg12.jpg")
    },
  },
  data() {
    return {
      profile: null,
      courses: null,
      connected: null,
      approved: null,
      showApproveButton: false
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getProfile();
      this.getUsersCourses();
    },
    getProfile() {
      this.directus.items('directus_users').readOne(this.$route.params.userid).then(response => {
        this.profile = response;
        this.isConnected();
      });
    },
    getUsersCourses() {
      this.directus.items('course').readByQuery({
        limit: -1,
        filter: {
          owner: this.$route.params.userid
        }
      }).then(response => {
        this.courses = response.data;
      })
    },
    isConnected() {
      this.directus.items('user_connections').readByQuery({
        limit: -1,
        filter: {
          _or: [
            {
              user_local: $user.user.id,
              user_foreign: this.profile.id,
            },
            {
              user_foreign: $user.user.id,
              user_local: this.profile.id,
            }
          ],
          status: {
            '_in': ['draft', 'published']
          }
        },
        sort: '-date_created'
      }).then(response => {
        if (response.data.length === 0) {
          this.connected = false;
        } else if (response.data.length > 0) {
          this.connected = true;
          this.approved = response.data[0].status === 'published'
          this.showApproveButton = response.data[0].user_foreign === $user.user.id && response.data[0].status === 'draft'
        }
      })
    },
    manageContact(action = null) {
      this.directus.items('user_connections').readByQuery({
        filter: {
          _or: [
            {
              user_local: $user.user.id,
              user_foreign: this.profile.id,
            },
            {
              user_foreign: $user.user.id,
              user_local: this.profile.id,
            }
          ],
        },
        sort: '-date_created'
      }).then(response => {
        var confirmResult;
        if (this.connected === false || action == 'approve') {
          if (response.data.length > 0 && response.data[0].user_foreign === $user.user.id) {
            if (action != 'approve') {
              confirmResult = confirm("Sicher dass der Kontakt entfernt werden soll?");
            }
            if (confirmResult !== false) {
              this.directus.items('user_connections').updateOne(response.data[0].id, {
                type: 'follower',
                status: ((action === 'approve') ? 'published' : 'draft')
              }).then(() => {
                this.connected = true;
                factory.checkOpenUserRequests().then(() => {
                  this.$parent.$refs.header.setOpenUserRequests();
                });
              })
            }
          } else {
            this.directus.items('user_connections').createOne({
              type: 'follower',
              user_local: $user.user.id,
              user_foreign: this.profile.id
            }).then(() => {
              this.connected = true;
            })
          }
        } else if (this.connected === true) {
          let toDeleteConnection = [];
          response.data.forEach(connection => {
            toDeleteConnection.push(connection.id);
          })
          confirmResult = confirm("Sicher dass die Anfrage entfernt werden soll?");
          if (confirmResult === true) {
            this.directus.items('user_connections').updateMany(toDeleteConnection, {
              status: 'archived'
            }).then(() => {
              this.connected = false;
              factory.checkOpenUserRequests().then(() => {
                this.$parent.$refs.header.setOpenUserRequests();
              });
            })
          }
        }
        if (confirmResult !== false) {
          this.showApproveButton = false;
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
