<template>
  <div class="wrapper">
    <div class="main">
      <div class="section users-content">
        <div class="container group" v-if="group">
          <div class="display--flex-between">
            <h2 class="course__title">{{ group.title }}</h2>
            <template v-if="isGroupOwner">
              <md-button class="md-primary md-just-icon md-round" @click.stop="openGroupModal()">
                <md-icon>edit</md-icon>
              </md-button>
            </template>
          </div>
          <p class="text--large course__description">{{ group.description }}</p>
          <h3>Kurse</h3>
          <div class="md-layout" v-if="group.courses">
            <div class="md-layout-item md-size-33 md-small-size-50" v-for="course in group.courses"
                 v-bind:key="course.id">
              <course-card :course="course.course_id"/>
            </div>
          </div>
          <h3>Mitglieder</h3>
          <div class="md-layout md-layout--user-card">
            <div class="md-layout-item md-size-25 md-small-size-100" v-for="user in group.users"
                 v-bind:key="user.id">
              <user-card v-if="user.directus_users_id" :user="user.directus_users_id"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal v-if="showGroupModal" class="modal--overlay">
      <template slot="header">
        <h3 class="modal-title">Gruppe bearbeiten</h3>
        <md-button class="md-simple md-just-icon md-round modal-default-button"
                   @click.stop="getGroup();showGroupModal=false;">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <div class="md-layout">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-field>
              <label>Titel</label>
              <md-input v-model="group.title"></md-input>
            </md-field>

            <md-field>
              <label>Beschreibung</label>
              <md-textarea v-model="group.description" md-autogrow></md-textarea>
            </md-field>

            <Select v-model="group.selectUsers" :options="contacts" :labels="['first_name', 'last_name']"
                    title="Mitglieder"/>

            <Select v-model="group.selectCourses" :options="courses" title="Kurse"/>
          </div>
        </div>
      </template>

      <template slot="footer">
        <div class="display--flex-between">
          <md-button class="md-danger md-simple" @click.stop="getGroup();showGroupModal=false;">Abbrechen
          </md-button>
          <md-button class="md-danger" @click.stop="deleteGroup()">Löschen</md-button>
          <md-button class="md-primary" @click.stop="updateGroup()">Speichern</md-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>

import {factory} from "@/services/factory";
import Mixins from "@/plugins/basicMixins";
import CourseCard from "@/components/custom/CourseCard";
import UserCard from "/src/components/custom/UserCard.vue";
import Modal from '@/components/custom/Modal.vue';
import Select from "@/components/custom/Select.vue";

export default {
  components: {CourseCard, UserCard, Modal, Select},
  mixins: [Mixins.HeaderImage],
  bodyClass: "page--content",
  props: ['user'],
  data() {
    return {
      group: null,
      isGroupOwner: false,
      showGroupModal: false,
      contacts: [],
      courses: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getGroup();
      this.getContacts();
      this.getCourses();
    },
    getContacts() {
      this.directus.items('directus_users').readByQuery({
        limit: -1,
        filter: {
          id: {
            _neq: $user.user.id
          }
        }
      }).then(response => {
        this.contacts = response.data;
      })
      /*
      factory.getContacts().then(response => {
        this.contacts = response
      })
       */
    },
    getCourses() {
      this.directus.items('course').readByQuery({
        limit: -1,
        filter: {
          status: 'published'
        }
      }).then(response => {
        this.courses = response.data;
      })
    },
    getGroup() {
      this.directus.items('user_groups').readOne(this.$route.params.groupid, {
        fields: [
          '*',
          'users.*',
          'users.directus_users_id.*',
          'courses.*',
          'courses.course_id.*',
          'courses.course_id.owner.*'
        ],
        deep: {
          courses: {
            _filter: {
              status: 'published'
            },
          },
          users: {
            _filter: {
              status: 'published'
            },
          }
        }
      }).then(response => {
        this.group = response;
        this.isGroupOwner = this.group.owner === this.$user.user.id;
      })
    },
    openGroupModal() {
      this.group.selectCourses = [];
      this.group.courses.forEach(course => {
        this.group.selectCourses.push(course.course_id);
      });

      this.group.selectUsers = [];
      this.group.users.forEach(user => {
        if(user.directus_users_id) {
          this.group.selectUsers.push(user.directus_users_id);
        }
      });

      this.showGroupModal = true;
    },
    updateGroup() {
      let promises = [];
      promises.push(this.directus.items('user_groups').updateOne(this.group.id, {
        title: this.group.title,
        description: this.group.description
      }));

      // UPDATE GROUP - USERS
      promises.push(factory.updateForeignTable(
          this.group.selectUsers,
          'user_groups_directus_users',
          {user_groups_id: this.group.id, status: 'published'},
          'id',
          'directus_users_id',
          [
            {fieldName: 'status', value: 'published'},
            {fieldName: 'user_groups_id', value: this.group.id},
            {fieldName: 'directus_users_id', key: 'id'}
          ]
      ));

      // Update GROUP - COURSES
      promises.push(factory.updateForeignTable(
          this.group.selectCourses,
          'user_groups_course',
          {user_groups_id: this.group.id, status: 'published'},
          'id',
          'course_id',
          [
            {fieldName: 'status', value: 'published'},
            {fieldName: 'user_groups_id', value: this.group.id},
            {fieldName: 'course_id', key: 'id'}
          ]
      ));

      Promise.all(promises).then(results => {
        let nestedPromises = [];
        results.forEach(result => {
          if (Array.isArray(result)) {
            result.forEach(promise => {
              nestedPromises.push(promise);
            })
          }
        })
        Promise.all(nestedPromises).then(() => {
          this.showGroupModal = false;
          this.init();
        });
      })
    },
    deleteGroup() {
      this.directus.items('user_groups').updateOne(this.group.id, {
        status: 'archived'
      }).then(() => {
        this.showGroupModal = false;
        this.init();
      })
    },
  }
};
</script>