// =========================================================
// * Vue Material Kit PRO - v1.3.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-kit-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import VueGlobalVar from "vue-global-var";
import App from "./App.vue";
import {directusService} from "@/services/directus.service";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "./router";
import {userService} from "@/services/user.service";
import MaterialKit from "./plugins/material-kit";
import VCalendar from 'v-calendar';
import moment from 'moment';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

moment.locale('de-ch');

var filter = function (text, length, clamp) {
    clamp = clamp || '';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.config.productionTip = false;

Vue.prototype.directus = directusService.init()

Vue.use(MaterialKit).use(VCalendar).use(CKEditor);

Vue.use(VueGlobalVar, {
    globals: {
        $user: userService.getUser(),
        $ckeditor: {
            editor: ClassicEditor,
            config: {
                link: {
                    addTargetToExternalLinks: true
                },
                toolbar: {
                    items: ['bold', 'italic', '|', 'link', '|', 'undo', 'redo', '|', 'numberedList', 'bulletedList']
                }
            }
        },
        $filePaths: {
            before: Vue.prototype.directus._url + '/assets/',
            after: '?access_token=',
            initToken: localStorage.getItem('auth_token'),
            smallImageAddition: '&fit=cover&width=150&quality=80',
            locaIcon: '/assets/'
        },
    },
});

Vue.filter('truncate', filter);

Vue.filter('formatToDate', function (value, mode) {
    if (value) {
        switch (mode) {
            case 'navigation':
                return moment(value).format('D.M')
                break;
            case 'dateAndDay':
                return moment(value).format('ddd DD.MM.YYYY')
                break;
            case 'chatDate':
                var date = moment(value);
                if (date.isSame(moment(), "day")) {
                    return date.format('HH:mm');
                } else if (date.isSame(moment().subtract(1, 'day'), "day")) {
                    return 'Gestern';
                } else {
                    return date.format('DD.MM.YY');
                }
                break;
            case 'time':
                return moment(value).format('HH:mm');
                break;
            case 'date':
                return moment(value).format('DD.MM.YY');
                break;
            case 'dateandtime':
                return moment(value).format('DD.MM.YY HH:mm');
                break;
            default:
                return moment(value).format('DD.MM.YYYY')
                break;
        }
    }
});

new Vue({
    router,
    render: h => h(App)
}).$mount("#app");
