/*

    Factory

 */

import {directusService} from "@/services/directus.service";
import {userService} from "@/services/user.service";
import axios from "axios";

const directus = directusService.init();

var dragActiveClass = 'draganddrop--onDrag';

export const factory = {
    getContacts,
    updateForeignTable,
    fileUpload,
    createFileUrl,
    setOpenUserRequests,
    checkOpenUserRequests,
    getOpenUserRequests,
    startDrag,
    handleDrag,
    onDrop,
    filePaths: {
        before: directus._transport._url + '/assets/',
        after: '?access_token='
    },
};

function getContacts(page = 0) {
    return new Promise(function (resolve) {
        directus.users.me.read({fields: ['id']}).then(meData => {
            // --- only confirmed connection
            directus.items('user_connections').readByQuery({
                fields: [
                    '*',
                    'user_foreign.*',
                    'user_local.*',
                ],
                filter: {
                    '_or': [
                        {user_local: meData.id},
                        {user_foreign: meData.id}
                    ],
                    status: 'published'
                },
                limit: -1
            }).then(connections => {
                let contacts = [];
                connections.data.forEach(connection => {
                    if (connection.user_foreign && connection.user_local) {
                        if (connection.user_local.id !== meData.id) {
                            connection.user_local.label = connection.user_local.first_name + ' ' + connection.user_local.last_name;
                            contacts.push(connection.user_local);
                        } else {
                            connection.user_foreign.label = connection.user_foreign.first_name + ' ' + connection.user_foreign.last_name;
                            contacts.push(connection.user_foreign);
                        }
                    }
                });
                resolve(contacts);
            });
        });
    });
}

function createObject(object, objectMapping) {
    let newObject = {};
    objectMapping.forEach(fieldMap => {
        if (fieldMap.value) {
            newObject[fieldMap.fieldName] = fieldMap.value;
        } else if (fieldMap.key) {
            newObject[fieldMap.fieldName] = object[fieldMap.key];
        }
    })

    return newObject;
}

function updateForeignTable(selectItems, foreignTable, foreignTableFilter, selectItemKey, DBItemKey, objectMapping) {
    //Update Course Days Date
    return directus.items(foreignTable).readByQuery({
        filter: foreignTableFilter
    }).then(response => {
        let promises = [];
        // archive items => items in db but not in selectItems
        response.data.forEach(DBItem => {
            if (selectItems.findIndex(selectItem => selectItem[selectItemKey] === DBItem[DBItemKey]) === -1) {
                promises.push(directus.items(foreignTable).updateOne(DBItem.id, {
                    status: 'archived'
                }));
            }
        })

        //create item => item in select but not in db
        selectItems.forEach(selectItem => {
            if (response.data.findIndex(DBItem => DBItem[DBItemKey] === selectItem[selectItemKey]) === -1) {
                promises.push(directus.items(foreignTable).createOne(createObject(selectItem, objectMapping)));
            }
        })

        return promises;
    })
}

function fileUpload(file) {
    const formData = new FormData();
    formData.append('title', file.name);
    formData.append('file', file);
    return directus.files.createOne(formData);
}

function createFileUrl(file, modifiers = '') {
    return userService.refreshAuth().then(token => {
        return directus._url + '/assets/' + file.filename_disk + '?access_token=' + token + modifiers
    })
}

function getOpenUserRequests() {
    return localStorage.getItem('openUserRequests');
}

function checkOpenUserRequests() {
    return new Promise(function (resolve) {
        if ($user.isLoggedIn === true) {
            directus.items('user_connections').readByQuery({
                fields: [
                    '*',
                    'user_local.*',
                ],
                filter: {
                    user_foreign: $user.user.id,
                    status: 'draft'
                }
            }).then(response => {
                setOpenUserRequests(response.data.length)
                resolve(response.data.length)
            });
        }
    });
}

function removeActiveDragClasses() {
    document.querySelectorAll('.' + dragActiveClass).forEach(activeList => {
        activeList.classList.remove(dragActiveClass);
    });
}

function startDrag(e, parentId, params = {}) {
    e.stopPropagation();

    e.dataTransfer.dropEffect = 'move'
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('parentId', parentId);

    if (params) {
        for (var key in params) {
            e.dataTransfer.setData(key, params[key]);
        }
    }
}

function handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();
    removeActiveDragClasses();

    const selectedItem = e.target.closest('[data-draganddrop-item]'),
        x = e.clientX,
        y = e.clientY,
        list = document.elementFromPoint(x, y).closest('[data-draganddrop]');

    if (list) {
        list.classList.add(dragActiveClass);
    }

    let swapItem = document.elementFromPoint(x, y) === null ? selectedItem : document.elementFromPoint(x, y).closest('[data-draganddrop-item]');

    if (swapItem) {
        swapItem = swapItem !== selectedItem.nextSibling ? swapItem : swapItem.nextSibling;
        list.insertBefore(selectedItem, swapItem);
    }
}

function onDrop(e = null, model = null) {
    removeActiveDragClasses();
    var returnValue = [];

    if (e && model) {
        e.target.closest('[data-draganddrop]').childNodes.forEach((sortItem, index) => {
            if (sortItem.id) {
                sortItem.dataset.sort = index;
                returnValue.push(directus.items(model).updateOne(sortItem.id, {
                    sort: index
                }));
            }
        });

        return returnValue;
    }
}

function setOpenUserRequests(length) {
    localStorage.setItem('openUserRequests', length)
    localStorage.setItem('openUserRequests_lastCheck', new Date().getTime())
}