import {Directus} from '@directus/sdk'

export const directusService = {
    init
};

function init () {
    var directusURL = 'http://localhost:8055';
    if(window.location.hostname === 'symmle.ch') {
        directusURL = 'https://directus.symmle.ch';
    } else if(window.location.hostname === 'dev.symmle.ch') {
        directusURL = 'https://dev.directus.symmle.ch';
    }

    return new Directus(directusURL)
}

