<template>
  <div class="wrapper">
    <notifications ref="notifications"/>
    <div class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="login__wrapper">
            <form @submit="resetPassword">
              <login-card header-color="green">
                <md-button slot="buttons" class="md-simple md-white" disabled>
                  Passwort zurücksetzten
                </md-button>

                <p slot="inputs" class="text-center">Um das Passwort zurückzusetzten, gib hier deine E-Mail Adresse ein und sende über den Button darunter das Formular ab. Anschliesend wirst du eine Mail mit weitern Informationen erhalten.</p>


                <md-field slot="inputs" class="md-form-group" id="email">
                  <md-icon>email</md-icon>
                  <label>E-Mail</label>
                  <md-input v-model="email" type="email" required/>
                  <span class="md-error">Bitte eine korrekte E-Mail Addresse eingeben</span>
                </md-field>

                <md-button type="submit" slot="footer" class="md-simple md-success md-lg">Zurücksetzen</md-button>
              </login-card>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {LoginCard} from "@/components";
import {Notifications} from "@/components";
import Mixins from "@/plugins/basicMixins";
import {userService} from "@/services/user.service";

export default {
  components: {
    LoginCard,
    Notifications
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "login-page",
  data() {
    return {
      image: require("@/assets/img/profile_city.jpg"),
      email: null
    };
  },
  mounted: function () {
  },
  methods: {
    resetPassword(e) {
      e.preventDefault();
      if(!this.email || this.email.length < 1) {
        this.$parent.alertManager('Bitte gib deine Mail Adresse in dem e-Mail Feld ein');
        return;
      }

      userService.resetPassword(this.email).then(() => {
        this.email = null
        this.$parent.alertManager('Dir wurde eine E-Mail mit weiteren Anweisungen zugeschickt.');
      }).catch(error => {
        console.log(error);
        this.$parent.alertManager('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.');
      })
    }
  }
};
</script>

<style lang="css"></style>
