<template>
  <div class="wrapper">
    <parallax
        class="section page-header header-filter"
        parallax-active="true"
        :style="headerStyle"
    />
    <div class="main main-raised">
      <div class="section users-content">
        <div class="container-fluid">
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <tabs ref="UsersGroupTabElement" id="UsersGroupTabElement" :tab-name="['Alle', 'Meine', 'Erstellen']" plain>
                <template slot="tab-pane-1" data-parent="UsersGroupTabElement">
                  <div class="md-layout-item md-size-33 md-small-size-100">
                    <md-field>
                      <label>Suche</label>
                      <md-input v-model="searchParam_groups" @keydown="searchGroups"></md-input>
                    </md-field>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-25 md-small-size-100" v-for="group in groups"
                         v-bind:key="group.id">
                      <group-card :group="group"/>
                    </div>
                  </div>

                  <div class="display--flex-center" v-if="groups_meta && groups_meta.page < groups_meta.pages">
                    <md-button class="md-primary md-round" @click.stop="getGroups(groups_meta.page + 1, 'append')">
                      Weitere Gruppen laden
                    </md-button>
                  </div>
                </template>
                <template slot="tab-pane-2" data-parent="UsersGroupTabElement">
                  <div class="md-layout-item md-size-33">
                    <md-field>
                      <label>Suche</label>
                      <md-input v-model="searchParam_myGroups" @keydown="searchMyGroups"></md-input>
                    </md-field>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-25 md-small-size-100" v-for="group in myGroups"
                         v-bind:key="group.id">
                      <group-card :group="group" />
                    </div>
                  </div>

                  <div class="display--flex-center" v-if="myGroups_meta && myGroups_meta.page < myGroups_meta.pages">
                    <md-button class="md-primary md-round" @click.stop="getMyGroups(myGroups_meta.page + 1, 'append')">
                      Weitere Gruppen laden
                    </md-button>
                  </div>
                </template>
                <template slot="tab-pane-3" data-parent="UsersGroupTabElement">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-25"></div>
                    <div class="md-layout-item md-size-50 md-small-size-100">
                      <md-field>
                        <label>Titel</label>
                        <md-input v-model="newGroup.title"></md-input>
                      </md-field>

                      <md-field>
                        <label>Beschreibung</label>
                        <md-textarea v-model="newGroup.description" md-autogrow></md-textarea>
                      </md-field>

                      <Select ref="newGroup_users" v-model="newGroup.users" :options="contacts"
                              :labels="['first_name', 'last_name']" title="Mitglieder"/>

                      <Select ref="newGroup_courses" v-model="newGroup.courses" :options="courses" title="Kurse"/>

                      <div class="display--flex-center pt-1">
                        <md-button class="md-primary md-round" @click.stop="createGroup()">
                          Erstellen
                        </md-button>
                      </div>
                    </div>
                  </div>
                </template>
              </tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import {factory} from "@/services/factory";
import Mixins from "@/plugins/basicMixins";
import GroupCard from "@/components/custom/GroupCard.vue";
import Tabs from "@/components/custom/Tabs.vue";
import Select from "@/components/custom/Select.vue";

export default {
  components: {
    GroupCard,
    Tabs,
    Select
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "page--content",
  props: {
    user: Object,
    image: {
      type: String,
      default: require("@/assets/img/bg12.jpg")
    },
  },
  data() {
    return {
      profiles: [],
      connections: [],
      openRequestsFromMe: [],
      openRequestsToMe: [],
      newGroup: {},
      groups: [],
      groups_meta: null,
      groups_count: 12,
      myGroups: [],
      myGroups_meta: null,
      myGroups_count: 12,
      contacts: [],
      courses: [],
      searchParam_groups: '',
      searchParam_myGroups: '',
      searchtimeout: null
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getGroups();
      this.getMyGroups();
      this.getContacts();
      this.getCourses();
    },
    getContacts() {
      factory.getContacts().then(response => {
        this.contacts = response
      })
    },
    getCourses() {
      this.directus.items('course').readByQuery({
        limit: -1,
        filter: {
          status: 'published'
        }
      }).then(response => {
        this.courses = response.data;
      })
    },
    updateConnection(requestId, newState) {
      //@todo delete
      debugger;
      /*
      this.directus.items('user_connections').updateOne(requestId, {
        status: newState
      }).then(() => {
        this.init();
      })
       */
    },
    getMyGroups(page = 1, action = 'insert'){
      var queryParams = {
        fields: [
          '*',
          'courses.*',
          'courses.course_id.*',
          'users.*',
          'users.directus_users_id.*',
        ],
        filter: {
          status: 'published',
          owner: this.$user.user.id
        },
        deep: {
          courses: {
            _filter: {
              status: 'published'
            },
            course_id: {
              _filter: {
                status: 'published'
              }
            }
          }
        },
        limit: this.myGroups_count,
        page: page,
        meta: '*'
      };

      if (this.searchParam_myGroups) {
        queryParams.search = this.searchParam_myGroups
      }
      this.directus.items('user_groups').readByQuery(queryParams).then(response => {
        if (action === 'append') {
          this.myGroups.push(...response.data);
        } else {
          this.myGroups = response.data
        }
        this.myGroups_meta = response.meta
        this.myGroups_meta.page = page;
        this.myGroups_meta.pages = Math.ceil(response.meta.filter_count / this.myGroups_count);
        console.log(this.myGroups_meta)
      });
    },
    searchMyGroups(){
      if (this.searchtimeout) {
        clearTimeout(this.searchtimeout);
      }
      this.searchtimeout = setTimeout(this.getMyGroups, 500);
    },
    getGroups(page = 1, action = 'insert') {
      var queryParams = {
        filter: {
          status: 'published'
        },
        limit: this.groups_count,
        page: page,
        meta: '*'
      }

      if (this.searchParam_groups) {
        queryParams.search = this.searchParam_groups
      }

      this.directus.items('user_groups').readByQuery(queryParams).then(response => {
        if (action === 'append') {
          this.groups.push(...response.data);
        } else {
          this.groups = response.data
        }
        this.groups_meta = response.meta
        this.groups_meta.page = page;
        this.groups_meta.pages = Math.ceil(response.meta.filter_count / this.groups_count);
      });
    },
    searchGroups() {
      if (this.searchtimeout) {
        clearTimeout(this.searchtimeout);
      }
      this.searchtimeout = setTimeout(this.getGroups, 500);
    },
    createGroup() {
      this.directus.items('user_groups').createOne({
        title: this.newGroup.title,
        description: this.newGroup.description,
        status: 'published',
        owner: this.$user.user.id
      }).then(response => {
        let user_groups_directus_users = [];
        if (this.newGroup.users && this.newGroup.users.length > 0) {
          this.newGroup.users.forEach(user => {
            user_groups_directus_users.push({
              status: 'published',
              user_groups_id: response.id,
              directus_users_id: user.id
            })
          })
        }

        let newGroupCourses = [];
        if (this.newGroup.courses && this.newGroup.courses.length > 0) {
          this.newGroup.courses.forEach(course => {
            newGroupCourses.push({
              status: 'published',
              user_groups_id: response.id,
              course_id: course.id
            })
          })
        }

        Promise.all([
          this.directus.items('user_groups_directus_users').createMany(user_groups_directus_users),
          this.directus.items('user_groups_course').createMany(newGroupCourses)]
        ).then(() => {
          this.newGroup = {};
          this.init();
        });
      })
    },
  }
};
</script>