<template>
  <div class="chat"
       :class="{'chat--active' : currentChat.id, 'chat--create' : createChatFlag, 'chat--open' : chatOpen, 'chat--search' : chatSearch}">
    <div class="chat__button" @click="toggleChat">
      <div class="chat__button-unread-items" v-if="globalUnreadCounter > 0">{{ globalUnreadCounter }}</div>
      <md-icon>chat_bubble</md-icon>
    </div>
    <div class="chat__container">
      <div class="chat__header">
        <div>
          <span class="chat__back" @click="chatBack"><md-icon>keyboard_backspace</md-icon></span>
          <span class="chat__search" @click="toggleSearch"><md-icon>search</md-icon></span>
        </div>
        <span class="chat__title" ref="chatTitle">
          <div class="chat__title-input" v-if="showTitleInput">
            <md-field class="formfield--hidden formfield--no-space">
              <md-input v-model="currentChat.title" @keyup="updateChatTitle" ref="chatTitleInput"></md-input>
            </md-field>
            <span @click="updateChatTitle">
              <md-icon>check</md-icon>
            </span>
          </div>
          <span class="chat__title-label" @click="showChatTitleInput" v-else>{{ header }}</span>
        </span>
        <div class="chat__actions">
          <template v-if="currentChat && currentChat.id">
            <span class="chat__edit" @click="toggleEditChat"
                  v-if="currentChat.type === 'group'">
              <md-icon>settings</md-icon>
            </span>
            <span class="chat__delete" @click="deleteChat"
                  v-if="currentChat && currentChat.id && (currentChat.type === 'single' || currentChat.type === 'group')">
            <md-icon v-if="deleteChatFlag === false">delete</md-icon>
            <div v-else>
              <span class="text--extra-small">sicher löschen?</span><md-icon>check</md-icon>
            </div>
          </span>
          </template>
          <span class="chat__close" @click="toggleChat">
            <md-icon>close</md-icon>
          </span>
        </div>
      </div>
      <div class="chat__search-wrapper">
        <md-field>
          <md-input v-model="searchString" ref="serachField" placeholder="Suche.." @keyup="search"></md-input>
        </md-field>
      </div>
      <div class="chat__inner">
        <div class="chat__new">
          <tabs ref="newChatTabs" id="newChatTabs" :tab-name="['Kontakte', 'Kurse']" plain
                class="tab--nav-center tab--no-padding-x tab--small tab--nav-between">
            <template slot="tab-pane-1" data-parent="newChatTabs">
              <div class="chat__new-wrapper">
                <div class="chat__new-input" :class="newGroupChatTitleInputClass">
                  <md-field :class="newGroupChatTitleErrorClass">
                    <label>Gruppenname</label>
                    <md-input v-model="newGroupChatTitle" ref="newGroupChatTitleInput"></md-input>
                  </md-field>
                </div>

                <div class="chat__new-list-wrapper">
                  <div class="chat__new-list chat__new-list--contacts">
                    <div class="iconlist">
                      <template v-for="item in contacts">
                        <div class="iconlist__item" :key="item.id" @click="toggleContact(item, null)"
                             v-if="item.status === searchState.active"
                             :class="{'iconlist__item--selected' : checkIfContactActive(item)}">
                          <IconList :item="{
                            status: item.status,
                            type: 'image',
                            posterSrc: item.posterSrc,
                            title: item.first_name + ' ' + item.last_name,
                            text: item.email,
                          }"/>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <md-button class="md-primary md-just-icon md-round" @click="createChat(null)">
                  <md-icon>add</md-icon>
                </md-button>
              </div>
            </template>
            <template slot="tab-pane-2" data-parent="newChatTabs">
              <div class="chat__new-list-wrapper">
                <div class="chat__new-list chat__new-list--groups">
                  <template v-for="course in courses">
                    <div class="chat__new-list-item" :key="course.id" @click="createChat(course)"
                         v-if="course.status === searchState.active">
                      {{ course.title }}
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </tabs>

        </div>
        <div class="chat__list-wrapper">
          <div class="chat__list-wrapper__inner">
            <div class="iconlist">
              <template v-for="item in chats">
                <div class="iconlist__item" @click="selectChat($event, item.id)" v-if="item.status === searchState.active"
                     :key="item.id">
                  <IconList :item="{
                  type: item.type,
                  title: item.title,
                  text: item.chatitem[0] ? item.chatitem[0].text : '',
                  counter: (item.currentChatItemStatus && item.currentChatItemStatus.counter) ? item.currentChatItemStatus.counter: 0,
                  date_created: (item.chatitem[0] && item.chatitem[0].date_created) ? item.chatitem[0].date_created : null
                }"/>
                </div>
              </template>
            </div>
          </div>
          <div class="chat__add" @click="addChat">
            <md-button class="md-primary md-just-icon md-round">
              <md-icon>add</md-icon>
            </md-button>
          </div>
        </div>

        <div class="chat__chat">
          <div class="chat__chat-edit-wrapper" v-if="currentChat.id && showChatEdit">
            <template v-if="currentChat.user_created === $user.user.id">
              <md-field>
                <label>Gruppenname</label>
                <md-input v-model="currentChat.title" @keyup="updateGroupTitle"></md-input>
              </md-field>
              <div class="iconlist">
                <template v-for="item in currentChat.users">
                  <div class="iconlist__item iconlist__item--selected" :key="item.id" @click="toggleUser($event, item)">
                    <IconList :item="{
                  type: 'image',
                  image: item.directus_users_id.avatarSrc,
                  title: item.directus_users_id.first_name + ' ' + item.directus_users_id.last_name,
                  text: item.directus_users_id.email,
                }"/>
                  </div>
                </template>
                <template v-for="item in contacts">
                  <div class="iconlist__item" :key="item.id" v-if="isInUserList(item, currentChat.users)"
                       @click="toggleUser($event, item)">
                    <IconList :item="{
                  type: 'image',
                  image: item.avatarSrc,
                  title: item.first_name + ' ' + item.last_name,
                  text: item.email,
                }"/>
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              <div class="iconlist">
                <template v-for="item in currentChat.users">
                  <div class="iconlist__item iconlist__item--readonly" :key="item.id">
                    <IconList :item="{
                  type: 'image',
                  image: item.directus_users_id.avatarSrc,
                  title: item.directus_users_id.first_name + ' ' + item.directus_users_id.last_name,
                  text: item.directus_users_id.email,
                }"/>
                  </div>
                </template>
              </div>
            </template>
          </div>
          <div class="chat__chat-content" ref="chatContent">
            <template v-for="(chatitem, index) in currentChat.chatitem">
              <div class="chat__chat-entry-wrapper" :key="chatitem.id" v-if="chatitem.status === searchState.active"
                   :class="{'chat__chat-entry-wrapper--last-read' : chatitem.chatitemstatus.length === 1}">
                <div class="chat__chat-date text--small" :key="chatitem.id"
                     v-if="isSameDate(index) && chatitem.date_created">
                  <p>{{ chatitem.date_created | formatToDate('date') }}</p>
                </div>
                <div class="chat__chat-entry" v-if="chatitem.date_created"
                     :class="{'chat__chat-entry--owner' : chatitem.user_created.id === $user.user.id}">
                  <div class="chat__chat-entry-text">{{ chatitem.text }}</div>
                  <div class="chat__chat-entry-footer">
                    <p class="chat__chat-entry-creator" v-if="chatitem.user_created.id !== $user.user.id">
                      {{ displayUserName(chatitem.user_created) }}</p>
                    <p class="chat__chat-entry-date">
                      {{ chatitem.date_created | formatToDate('time') }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="chat__chat-controls">
            <textarea placeholder="Nachricht" class="chat__chat-input" ref="chatInput" @keyup="submitChat($event)"/>
            <div class="chat__chat-submit" @click="submitChat(null)">
              <md-icon>send</md-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span ref="isChatMobile" class="chat__ismobile"></span>
  </div>
</template>

<script>
import moment from "moment";
import Tabs from "@/components/custom/Tabs.vue";
import IconList from "@/components/custom/IconList.vue";
import {factory} from "@/services/factory";

export default {
  name: "Chat",
  components: {
    Tabs,
    IconList,
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      chatOpen: false,
      createChatFlag: false,
      showTitleInput: false,
      headerDefaultText: 'Chats',
      header: 'Chats',
      chats: [],
      currentChat: {},
      courses: [],
      contacts: [],
      selectedContacts: [],
      newGroupChatTitle: '',
      newGroupChatTitleInputClass: '',
      newGroupChatTitleErrorClass: '',
      deleteChatFlag: false,
      searchString: '',
      chatSearch: false,
      searchState: {
        active: 'published',
        disabled: 'disabled'
      },
      globalUnreadCounter: 0,
      timeperiod: 2000,
      updateGroupTitleTimeout: null,
      selectedUserClass: 'iconlist__item--selected',
      showChatEdit: false
    }
  },
  methods: {
    init() {
      this.getChats();
      this.initNewChat();
      this.updateChatsPeriodically();
    },
    toggleChat() {
      if (this.chatOpen === false) {
        // open chat
        this.chatOpen = true;
        document.body.classList.add('body--chat-open');
        this
      } else {
        // close chat
        this.chatOpen = false;
        document.body.classList.remove('body--chat-open');
        this.changeChatView();
      }
    },
    changeChatView() {
      this.searchString = '';
      this.chatSearch = false;
      this.showTitleInput = false;
      this.newGroupChatTitleInputClass = '';
      this.selectedContacts = [];
      this.resetSearch();
    },
    initNewChat() {
      this.directus.items('course').readByQuery().then(response => {
        this.courses = response.data
      });

      factory.getContacts().then(contacts => {
        this.contacts = contacts;
      })
    },
    getChats(selectChat = null) {
      this.directus.items('chat').readByQuery({
        filter: {
          status: 'published',
        },
        fields: [
          '*',
          'chatitem.*',
          'chatitem.user_created.*',
          'users.*',
          'users.directus_users_id.*',
        ],
        deep: {
          chatitem: {
            _sort: '-date_created',
            _filter: {
              status: 'published'
            },
            limit: -1
          },
        },
        limit: -1
      }).then(chats => {
        this.globalUnreadCounter = 0;
        this.chats = [];

        if (chats.data.length === 0) {
          return;
        }

        //only unique entries
        this.uniqueChats(chats.data).forEach(chat => {
          this.chats.push(this.uniqueChatItem(chat));
        })

        this.chats.forEach(chat => {
          if (chat.type === 'single') {
            let tmpUser = chat.users[0].directus_users_id;
            if (chat.users[0].directus_users_id.id === $user.user.id) {
              tmpUser = chat.users[1].directus_users_id;
            }

            chat.title = tmpUser.first_name + ' ' + tmpUser.last_name
            if (chat.title.length < 1) {
              chat.title = tmpUser.email;
            }
          }

          this.setCurrentChatItemStatus(chat);
        });

        if (selectChat) {
          this.selectChat(null, selectChat);
          this.changeChatView();
        }
      });
    },
    updateCurrentChat(callback) {
      this.directus.items('chat').readOne(this.currentChat.id, {
        filter: {
          status: 'published',
        },
        fields: [
          '*',
          'chatitem.*',
          'chatitem.user_created.*',
        ],
        deep: {
          chatitem: {
            _sort: '-date_created',
            _filter: {
              status: 'published'
            }
          }
        }
      }).then(currentChat => {
        this.currentChat = this.uniqueChatItem(currentChat);
        this.setCurrentChatItemStatus(this.currentChat);
        this.chats[this.chats.findIndex(object => {
          return object.id === currentChat.id
        })].chatitem = this.currentChat.chatitem
        if (callback) callback.call();
      });
    },
    setCurrentChatItemStatus(chat, callback) {
      chat.currentChatItemStatus = {exist: false};
      chat.chatitem.forEach((item, index) => {
        // too many status => delete all
        if (item.chatitemstatus.length > 1) {
          this.directus.items('chatitemstatus').deleteMany(item.chatitemstatus);
        } else if (item.chatitemstatus.length === 1) {
          if (chat.currentChatItemStatus.exist === true) {
            //another item had already a chatitemstatus => delete
            this.directus.items('chatitemstatus').deleteMany(item.chatitemstatus);
          } else if (chat.currentChatItemStatus.exist === false) {
            chat.currentChatItemStatus = {
              chatItemStatusId: item.chatitemstatus[0],
              chatItemId: item.id,
              exist: true,
              counter: index
            }
            this.globalUnreadCounter += index
          }
        }
      });

      //no chat item status => all unread
      if (chat.currentChatItemStatus.exist === false) {
        chat.currentChatItemStatus.counter = chat.chatitem.length
        this.globalUnreadCounter += chat.chatitem.length
      }

      if (callback) callback.call();
    },
    uniqueChats(chats) {
      return chats.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    },
    uniqueChatItem(chat) {
      chat.chatitem = chat.chatitem.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
      return chat;
    },
    selectChat(e, chatId) {
      this.currentChat = this.chats.find(chat => chat.id === chatId);
      this.header = this.currentChat.title;
      this.changeChatView();
      this.setChatItemStatus(false);
    },
    chatBack() {
      this.header = this.headerDefaultText;
      this.updateCurrentChatActiveItem();
      this.currentChat = {};
      this.createChatFlag = false;
      this.changeChatView();
    },
    updateCurrentChatActiveItem() {
      if (this.currentChat.id) {
        this.currentChat.chatitem.filter(item => item.chatitemstatus.length > 0).forEach(item => {
          if (item.id !== this.currentChat.currentChatItemStatus.chatItemId) {
            item.chatitemstatus = [];
            this.currentChat.chatitem[0].chatitemstatus[0] = this.currentChat.currentChatItemStatus.chatItemStatusId;
          }
        })
      }
    },
    submitChat(e = null) {
      if (this.$refs.chatInput.value.length === 0) {
        return;
      }
      if (e && e.code !== 'Enter') {
        return;
      }

      if (e && e.code === 'Enter' && this.$refs.chatInput.value.length <= 1) {
        this.$refs.chatInput.value = '';
        return;
      }

      this.directus.items('chatitem').createOne({
        text: this.$refs.chatInput.value,
        chat: this.currentChat.id
      }).then(() => {
        this.$refs.chatInput.value = '';
        this.updateCurrentChat(this.setChatItemStatus)
      })
    },
    isSameDate(index) {
      if (this.currentChat.chatitem[index + 1]) {
        return !moment(this.currentChat.chatitem[index].date_created).isSame(moment(this.currentChat.chatitem[index + 1].date_created), "day")
      } else {
        return true
      }
    },
    addChat() {
      this.createChatFlag = true;
      this.header = "Chat erstellen"
      this.changeChatView();
    },
    createChat(course) {
      if (course && course.id) {
        this.directus.items('chat').readByQuery({
          filter: {
            type: 'course',
            course: course.id,
            status: 'published'
          }
        }).then(response => {
          if (response.data.length > 0) {
            this.selectChat(null, response.data[0].id);
          } else {
            this.directus.items('chat').createOne({
              title: course.title,
              course: course.id,
              type: 'course'
            }).then(response => {
              this.getChats(response.id);
            })
          }
        });
      } else if (this.selectedContacts.length > 0) {
        let type = 'single';
        let chatTitle = '';

        if (this.selectedContacts.length > 1) {
          type = 'group';
          if (!this.newGroupChatTitle || this.newGroupChatTitle.length < 2) {
            this.newGroupChatTitleErrorClass = 'md-error';
            this.$parent.alertManager('Der Gruppenname ist zu kurz (min. 3 Zeichen)', 'error-message', true, 5000);
            this.$refs.newGroupChatTitleInput.$el.focus();
            return;
          }
          this.newGroupChatTitleErrorClass = '';
          chatTitle = this.newGroupChatTitle
        }


        let selectedContactIds = [];
        this.selectedContacts.forEach(selectedContact => {
          selectedContactIds.push({
            "chat_id": "+",
            "directus_users_id": {
              "id": selectedContact.id
            }
          })
        })

        selectedContactIds.push({
          "chat_id": "+",
          "directus_users_id": {
            "id": $user.user.id
          }
        })

        //create group
        this.directus.items('chat').createOne({
          title: chatTitle,
          type: type,
          users: {
            "create": selectedContactIds,
          }
        }).then(response => {
          this.getChats(response.id);
        })
      }
    },
    toggleContact(contact) {
      let index = this.selectedContacts.indexOf(contact);
      if (index >= 0) {
        this.selectedContacts.splice(index, 1);
      } else {
        this.selectedContacts.push(contact);
      }

      if (this.selectedContacts.length > 1) {
        this.newGroupChatTitleInputClass = 'chat__new-input--active';
      } else {
        this.newGroupChatTitleInputClass = '';
      }
    },
    checkIfContactActive(contact) {
      return this.selectedContacts.indexOf(contact) >= 0;
    },
    deleteChat() {
      this.changeChatView();
      if (this.deleteChatFlag === false) {
        this.deleteChatFlag = true;
      } else if (this.deleteChatFlag === true) {
        this.deleteChatFlag = false;
        let name = $user.user.first_name + ' ' + $user.user.last_name

        if (name.length > 0) {
          name = $user.user.email
        }

        this.directus.items('chatitem').createOne({
          text: name + ' hat den Chat verlassen',
          chat: this.currentChat.id
        }).then(() => {
          this.directus.items('chat').updateOne(this.currentChat.id, {
            "users": {
              "delete": {
                "directus_users_id": {
                  "id": $user.user.id
                }
              }
            }
          })
        })

        if (this.currentChat.type === 'single') {

        }
        if (this.currentChat.type === 'group') {
          this.directus.items('chat_directus_users').updateByQuery({
            filter: {
              chat_id: this.currentChat.id,
              directus_users_id: $user.user.id
            }
          }, {
            status: 'archived'
          })
        }
      }
    },
    showChatTitleInput(e) {
      if (e && e.target.tagName.toLowerCase() === 'input') {
        return;
      }
      if (this.currentChat && this.currentChat.type === 'group') {
        this.showTitleInput = true
        setTimeout(() => {
          this.$refs.chatTitleInput.$el.focus();
        }, 50);

      }
    },
    updateChatTitle(e) {
      if (e.code && e.code !== 'Enter') {
        return
      }

      if (this.currentChat && this.currentChat.type === 'group') {
        this.directus.items('chat').updateOne(this.currentChat.id, {
          title: this.currentChat.title
        }).then(currentChat => {
          this.header = currentChat.title
        });
      }
      this.showTitleInput = false;
    },
    toggleSearch() {
      if (this.chatSearch === false) {
        //open
        this.chatSearch = true;
        setTimeout(() => {
          this.$refs.serachField.$el.focus();
        }, 50);
      } else {
        this.chatSearch = false;
        this.resetSearch();
      }
    },
    search() {
      let searchWord = this.$refs.serachField.value.toLowerCase()
      if (this.createChatFlag === true) {
        this.contacts.forEach(contact => {
          contact.status = this.findWordInDirectusUser(contact, searchWord) ? this.searchState.active : this.searchState.disabled;
        });
        this.courses.forEach(course => {
          course.status = course.title.toLowerCase().includes(searchWord) === true ? this.searchState.active : this.searchState.disabled;
        })
      } else if (this.currentChat.id) {
        this.currentChat.chatitem.forEach(item => {
          item.status = item.text.toLowerCase().includes(searchWord) === true ? this.searchState.active : this.searchState.disabled;
        })
      } else {
        this.chats.forEach(chat => {
          if (chat.type === 'group' || chat.type === 'course') {
            if (chat.title.toLowerCase().includes(searchWord)) {
              chat.status = this.searchState.active;
            } else {
              chat.status = this.searchState.disabled;
            }
          } else if (chat.type === 'single') {
            if (chat.users[0].directus_users_id && chat.users[1].directus_users_id) {
              let result = false;
              if (chat.users[0].directus_users_id.id !== $user.user.id) {
                result = this.findWordInDirectusUser(chat.users[0].directus_users_id, searchWord)
              } else {
                result = this.findWordInDirectusUser(chat.users[1].directus_users_id, searchWord)
              }
              chat.status = result === true ? this.searchState.active : this.searchState.disabled;
            }
          }
        })
      }
    },
    resetSearch() {
      this.contacts.forEach(contact => {
        contact.status = this.searchState.active;
      });

      this.courses.forEach(course => {
        course.status = this.searchState.active;
      })

      if (this.currentChat.id) {
        this.currentChat.chatitem.forEach(item => {
          item.status = this.searchState.active;
        })
      }

      this.chats.forEach(chat => {
        chat.status = this.searchState.active
      })
    },
    findWordInDirectusUser(user, searchWord) {
      let result = false;
      if (user.first_name.toLowerCase().includes(searchWord)) {
        result = true;
      }
      if (user.last_name.toLowerCase().includes(searchWord)) {
        result = true;
      }
      if (user.email.toLowerCase().includes(searchWord)) {
        result = true;
      }

      return result;
    },
    displayUserName(user) {
      if (user.first_name && user.last_name) {
        return user.first_name + ' ' + user.last_name;
      }
      return user.email
    },
    updateChatsPeriodically() {
      setInterval(() => {
        this.timeperiod = this.chatOpen === true ? 10000 : 30000;
        if ((this.chatOpen === true && this.createChatFlag === true) || (this.chatOpen === true && typeof this.currentChat.id !== 'undefined')) {
          return;
        }
        this.getChats();
      }, this.timeperiod);
    },
    setChatItemStatus(updateCurrentChatActiveItem = true) {
      if (this.currentChat.chatitem.length > 0) {
        if (this.currentChat.currentChatItemStatus.exist === false) {
          this.directus.items('chatitemstatus').createOne({
            chatitem: this.currentChat.chatitem[0].id
          }).then(response => {
            this.globalUnreadCounter -= this.currentChat.currentChatItemStatus.counter
            this.currentChat.currentChatItemStatus = {
              exist: true,
              chatItemId: response.chatitem,
              chatItemStatusId: response.id,
              counter: 0
            }

            if (updateCurrentChatActiveItem === true) {
              this.updateCurrentChatActiveItem();
            }
          })
        } else {
          this.directus.items('chatitemstatus').updateOne(this.currentChat.currentChatItemStatus.chatItemStatusId, {
            chatitem: this.currentChat.chatitem[0].id
          }).then(response => {
            this.globalUnreadCounter -= this.currentChat.currentChatItemStatus.counter
            this.currentChat.currentChatItemStatus = {
              exist: true,
              chatItemId: response.chatitem,
              chatItemStatusId: response.id,
              counter: 0
            }

            if (updateCurrentChatActiveItem === true) {
              this.updateCurrentChatActiveItem();
            }
          })
        }
      }
    },
    updateGroupTitle() {
      clearTimeout(this.updateGroupTitleTimeout);
      this.updateGroupTitleTimeout = setTimeout(() => {
        this.directus.items('chat').updateOne(this.currentChat.id, {
          title: this.currentChat.title
        }).then(response => {
          if (this.currentChat.id) {
            this.header = response.title;
          }
        });
      }, 800);
    },
    toggleEditChat() {
      this.showChatEdit = !this.showChatEdit;
    },
    toggleUser(e, user) {
      let item = e.target.closest('.iconlist__item');
      let options = {
        fields: [
          '*',
          'users.*',
          'users.user.directus_users_id.*',
        ]
      }

      if (item.classList.contains(this.selectedUserClass)) {
        options.users = {
          delete: [user.id]
        }
      } else {
        options.users = {
          create: [
            {
              chat_id: this.id,
              directus_users_id: {
                id: user.id
              }
            }
          ]
        }
      }

      this.directus.items('chat').updateOne(this.currentChat.id, options).then(response => {
        if (options.users.delete) {
          this.currentChat.users = this.currentChat.users.filter(item => {
            return item.id !== user.id;
          });
        } else if (options.users.create) {
          this.currentChat.users.push({
            chat_id: this.currentChat.id,
            directus_users_id: user,
            id: response.users[response.users.length - 1]
          })
        }
      })
    },
    isInUserList(userToCheck, list) {
      return !list.find(item => {
        return item.directus_users_id.id === userToCheck.id
      });
    },
    addUserToGroupChat() {
      // @todo
    },
    removeUserFromGroupChat() {
      //@todo
    },
    loadMoreChats() {
      //@todo
    },
    loadMoreChatItems() {
      // @todo
    },
  }
};

//@todo scoll if too many enteries (new chat, chat & open chats)
//@todo archive chat
</script>
