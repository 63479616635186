<!-- eslint-disable vue/no-mutating-props -->

<template>
  <div class="courseday">
    <div class="courseday__header" :class="{'courseday__header--active' : courseDay.date == 1}">
      <div class="courseday__title">
        <template v-if="isCourseOwner">
          <md-field class="formfield--hidden">
            <md-input v-model="courseDay.title" placeholder="Titel" v-on:keyup="editCourseDay();"></md-input>
          </md-field>
        </template>
        <template v-else>
          {{ courseDay.title }}
        </template>
      </div>
      <div class="courseday__date text--extra-small">
        <template v-if="isCourseOwner">
          <span @click.stop="showCourseDayDateModal=true">{{ courseDay.date | formatToDate('dateAndDay') }}</span>
        </template>
        <template v-else>
          {{ courseDay.date | formatToDate('dateAndDay') }}
        </template>
      </div>
    </div>
    <div class="courseday__content" @drop="onDrop($event, courseDay)" @dragover.prevent @dragenter.prevent>
      <div class="coursedaydayitem__wrapper" v-if="courseDayDayItems" data-draganddrop>
        <template v-for="(courseDayDayItem, index) in courseDayDayItems">
          <CourseDayDayItem v-bind:key="courseDayDayItem.id" :courseDayDayItem.sync="courseDayDayItems[index]"
                            :isCourseOwner="isCourseOwner" v-if="courseDayDayItem"/>
        </template>
      </div>
      <div class="courseday__footer">
        <md-button class="md-primary md-just-icon md-round md-sm" v-if="isCourseOwner"
                   @click="addCourseDayContent()">
          <md-icon>add</md-icon>
        </md-button>
      </div>
    </div>
    <modal v-if="showCourseDayDateModal" class="modal--overlay">
      <template slot="header">
        <h4 class="modal-title">Datum anpassen</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="showCourseDayDateModal=false">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <div class="md-layout">
          <div class="md-layout-item">
            <v-date-picker v-model="courseDay.dateDefaultString"/>
          </div>
        </div>
      </template>

      <template slot="footer">
        <div class="display--flex-between">
          <md-button class="md-info md-simple" @click="showCourseDayDateModal=false">Abbrechen</md-button>
          <md-button class="md-primary" @click="updateCourseDayDate()">Speichern</md-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */

import CourseDayDayItem from "@/components/custom/CourseDayDayItem.vue";
import Modal from '@/components/custom/Modal.vue';
import moment from "moment";
import {factory} from "@/services/factory";

export default {
  name: "CourseDay",
  components: {Modal, CourseDayDayItem},
  props: {
    courseDay: Object,
    isCourseOwner: {type: Boolean, default: false}
  },
  data() {
    return {
      showCourseDayDateModal: false,
      courseDayDayItems: null,
      courseDayListItems: null
    }
  },
  watch: {
    courseDay: function () {
      this.getCourseItemContent();
    }
  },
  mounted() {
    this.getCourseItemContent();
  },
  methods: {
    getCourseItemContent() {
      if (this.courseDay.courseitemcontent.length > 0) {
        this.directus.items('courseitemcontent').readByQuery({
          sort: 'sort,title',
          filter: {
            id: {
              '_in': this.courseDay.courseitemcontent
            },
            status: 'published',
          },
          fields: [
            '*',
            'files.*',
            'files.directus_files_id',
            'files.directus_files_id.*',
          ],
          deep: {
            files: {
              _sort: 'sort',
              _filter: {
                status: 'published',
                directus_files_id: {
                  _nnull: true
                }
              },
            }
          }
        }).then(response => {
          this.courseDayDayItems = response.data
        })
      } else {
        this.courseDayDayItems = [];
      }
    },
    updateCourseDayDate() {
      this.directus.items('courseitem').updateOne(this.courseDay.id, {
        date: moment(this.courseDay.dateDefaultString).format('x')
      }).then(() => {
        this.$parent.getCourse();
        this.showCourseDayDateModal = false;
        this.$parent.$parent.alertManager('Der Kurstag', 'success');
      }).catch(error => {
        console.log(error)
        this.$parent.$parent.alertManager('Der Kurstag', 'danger');
      });
    },
    editCourseDay() {
      if (this.courseitemTimer) {
        clearTimeout(this.courseitemTimer);
        this.courseitemTimer = null;
      }
      this.courseitemTimer = setTimeout(() => {
        this.directus.items('courseitem').updateOne(this.courseDay.id, {
          title: this.courseDay.title
        }).then(() => {
          this.$parent.$parent.alertManager('Der Kurstag', 'success');
        }).catch(error => {
          console.log(error);
          this.$parent.$parent.alertManager('Der Kurstag', 'danger');
        })
      }, 200);
    },
    addCourseDayContent() {
      this.directus.items('courseitemcontent').createOne({
        courseitem: this.courseDay.id,
      }).then(response => {
        response.newItem = true;
        this.courseDay.courseitemcontent.push(response.id)
        this.courseDayDayItems.push(response)
      })
    },
    onDrop(e, courseDay) {
      if (!this.isCourseOwner) {
        return;
      }

      if (e.dataTransfer.files.length > 0) {
        var files = e.dataTransfer.files;
        e.preventDefault();
        this.directus.items('courseitemcontent').createOne({
          courseitem: this.courseDay.id,
        }).then(courseitemcontent => {
          //courseitemcontent.newItem = true;

          var promises = [];
          files.forEach(file => {
            promises.push(factory.fileUpload(file));
          });

          Promise.all(promises).then(responses => {
            var promises2 = [];
            responses.forEach(fileObj => {
              promises2.push(this.directus.items('courseitemcontent_files').createOne({
                status: 'published',
                courseitemcontent_id: courseitemcontent.id,
                directus_files_id: fileObj.id
              }));
            });
            Promise.all(promises2).then(() => {
              this.courseDay.courseitemcontent.push(courseitemcontent.id)
              this.courseDayDayItems.push(courseitemcontent)
              this.getCourseItemContent();
            }).catch(error => {
              this.$parent.$parent.$parent.alertManager('Beim Upload der Datei ist ein Fehler aufgetreten', 'error-message', false, 5000, {
                type: 'fileUploadError',
                message: error
              })
            });
          }).catch(error => {
            console.log(error)
            this.$parent.$parent.alertManager('Beim Upload der Datei ist ein Fehler aufgetreten', 'error-message', false, 5000, {
              type: 'fileUploadError',
              message: error
            })
          });
        });
      } else {
        this.$parent.onDrop(e, courseDay);
      }
    }
  }
};
</script>